/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react' ; 
import { motion } from 'framer-motion';
import { WarningType } from '../../../store/types.store';
import BasePopup from './BasePopup';
import { useTranslation } from 'react-i18next';


export interface WarningProps extends WarningType{
    handleClose : () => void
}


export default function PopupWarning(props:WarningProps) {
    const nameClass = props.nameClass ? props.nameClass : ""
    const text = props.text ? props.text : "No text in arguments"
    const handleYes = props.handleYes ? props.handleYes : () => console.log('No function handleYes() in arguments')
    const handleNo = props.handleNo ? props.handleNo : () => console.log('No function handleYes() in arguments')
    
    const { i18n } = useTranslation();
    const i18n_language = i18n.language;
    /*
        Needed props : 
            - text : 
            - handleYes ; 
            - handleNo : 
    */
    useEffect(() => {
        const handleKeyPress = (event:KeyboardEvent):void => {
            if (event.key === "Enter") {
                handleYes();
                props.handleClose(); 

            }
        };
    
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <BasePopup handleClose = {() => props.handleClose()} nameClass={`popup-warning ${nameClass}`}>
            <p>{text}</p>
            <div>
                <motion.div
                    onClick = {() => {
                        props.handleClose()
                        handleYes()
                    }}
                >
                    Oui
                </motion.div>
                <motion.div
                    onClick={() => {
                        props.handleClose()
                        handleNo()
                    }}
                >
                    Non
                </motion.div>
            </div>
            

        </BasePopup>
    )
}