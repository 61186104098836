/* eslint-disable react-hooks/exhaustive-deps */
import { Icons } from "tc-minibox";
import {ReactComponent as UploadSVG} from '../../../../assets/svg/illustrations/upload-bro.svg'
import ImportModule, { ImportProps } from "../../../../tools/components/general/ImportModule";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import MainButton from "../../../../tools/components/general/MainButton";
import { AnimatePresence } from "framer-motion";
import BasePopup from "../../../../tools/components/general/BasePopup";
import {motion} from 'framer-motion'
import { hostname } from "../../../../config/server.config";


export interface PicturesMerchProps {
    update : boolean,
    pictures : any, 
    setPictures : React.Dispatch<React.SetStateAction<any>>, 
}

export interface PicturesMerchManager {
    add : boolean, 
    selected_preview : File | string, 
}

export default function PicturesMerch(props:PicturesMerchProps) {
    const {t} = useTranslation()
    const [manager, setManager] = useState<PicturesMerchManager>({
        add : false, 
        selected_preview : ""
    })

    useEffect(() => {
        if(props.update) {
            setManager((state:any) => {return{...state, selected_preview : props.pictures[0]}})
        }
    }, [])

    return (
        <React.Fragment>
            <AnimatePresence>
                {
                    manager.add ? 
                        <PopupImportPicturesMerch 
                            manager = {manager}
                            setManager={setManager}
                            pictures={props.pictures}
                            setPictures = {props.setPictures}
                            handleClose = {() => setManager((state:any) => {return{...state, add : false}})}
                        />
                    : 
                        null
                }
            </AnimatePresence>
            <div className="pictures-merch">
                {
                    props.pictures.length > 0 ? 
                        <React.Fragment>
                            <div className="pictures-merch--preview">
                                {
                                    typeof manager.selected_preview === "string" ? 
                                        <img src={`${hostname}/${manager.selected_preview}`} alt="" />
                                    : 
                                        <img src={URL.createObjectURL(manager.selected_preview)} alt="" />
                                }
                            </div>
                            <div className="pictures-merch--controller">
                                {props.pictures.map((picture:any, index:number) => {
                                    return (
                                    <motion.div 
                                        whileHover={{scale:1.05}}
                                        whileTap={{scale:0.95}}
                                        onClick= {() => setManager((state:any) => {return{...state, selected_preview : picture}})}
                                        className={`pictures-merch--controller--item ${picture === manager.selected_preview ? "selected" : ""}`}
                                        key = {index}
                                    >
                                        {
                                            typeof picture === "string" ?
                                                <div className="pictures-merch--controller--item--preview">
                                                    <img src={`${hostname}/${picture}`} alt="" />
                                                </div>
                                            : 
                                                <div className="pictures-merch--controller--item--preview">
                                                    <img src={URL.createObjectURL(picture)} alt="" />
                                                </div>
                                        }
  
                                        <div 
                                            onClick = {() => {
                                                props.setPictures(props.pictures.filter((item:any) => item !== picture))
                                            }}
                                            className="pictures-merch--controller--item--delete"
                                        >
                                            <Icons name = "xmark" mode = "duotone" color = "white" />
                                        </div>
                                    </motion.div>
                                )
                                })}
                                <motion.div 
                                    whileHover={{scale:1.05}}
                                    whileTap={{scale:0.95}}
                                    onClick = {() => setManager((state:any) => {return{...state, add : true}})}
                                    className="add-pictures-merch"
                                >
                                    <Icons name = "plus" mode = "duotone" color = "white" />
                                </motion.div>

                            </div>
                        </React.Fragment>
                    : 
                        <div className="pictures-merch--nodata">
                            <UploadSVG />
                            <MainButton 
                                handleClick = {() => setManager((state:any) => {return{...state, add : true}})}
                            >
                                {t("commons.import")}
                            </MainButton>
                        </div>

                }

            </div>
        </React.Fragment>
    )
}

export interface PopupImportPicturesMerchProps {
    manager : PicturesMerchManager,
    setManager : React.Dispatch<React.SetStateAction<PicturesMerchManager>>,
    pictures : any, 
    setPictures : React.Dispatch<React.SetStateAction<any>>, 
    handleClose : () => void
}

export function PopupImportPicturesMerch(props:PopupImportPicturesMerchProps) {
    // * GENERAL VARIABLES
    const {t} = useTranslation()
    
    // * CONFIG COMPONENTS
    const import_config: ImportProps = {
        multiple: true,
        mode: "normal",
        subtitle: t("merchandises.manager.text-import"),
        nameClass: 'new-affair--import',
        handleSubmit: (files: File[]) => {
          // Définissez les types MIME acceptés ici.
          const acceptedMimeTypes = ['image/jpeg', 'image/png', 'image/jpg']; // Exemple de types MIME acceptés
      
          // Filtrez les fichiers pour inclure uniquement ceux avec des types MIME acceptés.
          const filteredFiles = files.filter(file => acceptedMimeTypes.includes(file.type));
      
          // Mettez à jour l'état avec les fichiers filtrés.
          props.setPictures([...props.pictures, ...filteredFiles])
          props.setManager((state:any) => {return{...state, selected_preview : [...props.pictures, ...filteredFiles][0]}})
          props.handleClose()
        }
    }
    return (
        <BasePopup nameClass="popup-pictures-merch" handleClose = {props.handleClose}>
            <ImportModule {...import_config} />
        </BasePopup>
    )
}