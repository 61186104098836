import { useState } from "react"
import BasePopup from "../../../tools/components/general/BasePopup"
import { icons_config } from "../../../config/context.config"
import { Table } from "tc-minibox"
import { TableForProps } from "tc-minibox/dist/types/table/index.table"
import { useTranslation } from "react-i18next"
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../../store/global/actions.global"
import { account_user } from "../../../store/account/selector.account"
import { useDispatch, useSelector } from "react-redux"
import { ServerProps } from "../../../config/server.config"


export interface WithdrawalPaymentsProps extends ServerProps {
    title : string, 
    payments : any, 
    setPayments : React.Dispatch<React.SetStateAction<any>>,
    handleClose : () => void,
}

export default function WithdrawalPayments(props:WithdrawalPaymentsProps) {
    // * GENERAL VARIABLES 
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const translate = (e:string) => t(`transactions.${e}`)
    const user_account = useSelector(account_user)
    const admin = user_account.infos.permission_type === "admin"

    // * STATES
    const [payments, setPayments] = useState<any>(props.payments)
    
    // * VARIABLES
    const tbody = payments.map((trans:any) => {
        return {
            ...trans, 
            amount : `${trans.amount.toFixed(2)} €`, 
            exchange_rate : `${trans.exchange_rate} IDR`, 
            total_idr : `${(trans.amount * trans.exchange_rate).toFixed(2)} IDR`,
            withdrawal_code : trans.withdrawal ? trans.withdrawal.code : "", 
            external_text : trans.external ?  t('commons.true') : t('commons.false')
        }
    })

    // * FUNCTIONS 
    const handleDelete = (e:any) => {
        const data = e.map((el:any )=> el.id)

        props.server.post('/transactions/delete', {ids : data})
        .then((res:any) => {
            //props.setTransactions((state:any) => {return [...state, res.data.data]})
            dispatch(addRequestStatus(res.data))
            props.setPayments((state:any) => {
                return state.filter((el:any) => {
                    return !data.includes(el.id)
                })
            })

            setPayments((state:any) => {
                return state.filter((el:any) => {
                    return !data.includes(el.id)
                })
            })
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    // * COMPONENT CONFIG
    const table_config:TableForProps = {
        height : 30, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Date", 
                    name : "date",
                    type : "date"
                }, 
                style : {
                    width : `calc(${100/4}%) `, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Code", 
                    name : "code",
                }, 
                style : {
                    width : `calc(${100/4}%)`, 
                    tbody : {

                    }
                }
            }, 
            {
                info : {
                    text : translate("amount"), 
                    name : "amount",
                }, 
                style : {
                    width : `calc(${100/4}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : translate("rate"), 
                    name : "exchange_rate",
                }, 
                style : {
                    width : `calc(${100/4}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            },
            {
                info : {
                    text :"IDR", 
                    name : "total_idr",
                }, 
                style : {
                    width : `calc(${100/4}%)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            },
        ], 
        data : tbody,
        setData : setPayments,
        header : {
            buttons : {
                include : admin ? [""] : [], 
                actions : {
                    /*add: () => setManager(state => {return {...state, add : true}}), */
                    delete : (e:any) => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ? Cette action est irréversible.", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 

                }
            }
        }, 
        //handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }
    return (
        <BasePopup nameClass="withdrawal-payments" handleClose={props.handleClose}>
            <h1>{props.title}</h1>
            <Table {...table_config} />
        </BasePopup>
    )
}