/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { DataType, TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { icons_config, stepsText_position } from "../../config/context.config";
import { ServerProps } from "../../config/server.config";
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import StepsText from "../../tools/components/general/StepsText";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../store/global/actions.global";
import { Icons, Table } from "tc-minibox";
import { AnimatePresence } from "framer-motion";
import InvoicesMan from "./manager/InvoicesMan";
import InvDetail from "./detail/InvDetail";
import { account_user } from "../../store/account/selector.account";
import { inv_types } from "./invoices.utils";


export type InvoicesManager = {
    add : boolean
    initialize : boolean, 
    mode : "listing" | "detail", 
    selected_data? : number | null,
    filter : "all" | "raw_materials" | "finished_products",
    update : {
        status : boolean, 
        content : any
    } 
}

const initialize_update = {
    status : false, 
    content : {}
}

export default function Invoices(props:ServerProps) {
    // * GENERAL VARIABLES
    const {t} = useTranslation()
    const translate_table = (text:string) => t(`invoices.table.${text}`)
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)
    const admin = user_account.infos.permission_type === "admin"

    // * STATES 
    const [invoices, setInvoices] = useState<DataType>([])
    const [manager, setManager] = useState<InvoicesManager>({
        add : false, 
        initialize : false,
        filter : "all", 
        mode : "listing",
        selected_data : null, 
        update : {status : false, content : {}}
    })

    // * VARIABLES

    const tbody = invoices.map(el => {
        return {
            ...el, 
            rp_amount_text : `${el.rp_amount} IDR`, 
            total_paid_text : `${el.total_paid} IDR`,
            rest_to_pay_text : el.rest_to_pay !== 0 ? `${el.rest_to_pay} IDR` : t("invoices.table.paid")
        }
    })

    const filter_config = [
        {
            name : "all", 
            label : translate_table("all"),
        }, 
        ...inv_types
    ]


    // * USE EFFECTS
    
    // * FUNCTIONS
    const handleDelete = (e:any) => {

        if(e.find((el:any) => el.total_paid !== 0)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.status.delete-transaction-presence")
            }))
        }

        if(e.find((el:any) => el.merchandises > 0)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.status.delete-merch-presence")
            }))
        }

        if(e.length > 0) {
            dispatch(setLoaderStatus({status : true}))
            const selected_data = e.map((sup:any) => sup.id)

            props.server.post('invoices/delete', selected_data)
            .then(res => {
                setInvoices(state => state.filter((st:any) => !selected_data.includes(st.id)))
                dispatch(addRequestStatus({
                    status : res.data.status, 
                    message : t(res.data.message)
                }))
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }

    const handleDoubleClick = (e:any) => {
        setManager(state => {return {...state, selected_data : e.id, mode : "detail"}})
    }

    // * COMPONENT CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : translate_table("date"), 
                    name : "date",
                    type : "date"
                }, 
                style : {
                    width : `100px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : translate_table("inv-number"), 
                    name : "inv_number",
                }, 
                style : {
                    width : `calc(50% - 275px)`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : translate_table("supplier"), 
                    name : "supplier.name", 
                }, 
    
                style : {
                    width : `calc(50% - 275px)`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 

            {
                info : {
                    text : translate_table("rp_amount"), 
                    name : "rp_amount_text",
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            },

            {
                info : {
                    text : translate_table("paid"), 
                    name : "total_paid_text",
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            },
            {
                info : {
                    text : translate_table("rest_to_pay"), 
                    name : "rest_to_pay_text",
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },
            /*{
                info : {
                    text : translate_table("average_rate"), 
                    name : "exchange_rate",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },
            {
                info : {
                    text : translate_table("status"), 
                    name : "status",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },*/
        ], 
        data : tbody,
        setData : setInvoices,
        header : {
            buttons : {
                include : admin ? ["add", "delete"] : ["add"], 
                actions : {
                    add: () => setManager(state => {return {...state, add : true}}), 
                    delete : (e:any) => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 

                }
            }, 
            custom : (data:any) => {
                return (
                    <React.Fragment>
                        <div 
                            onClick = {() => setManager(state => {return{...state, initialize : false}})}
                            className="refresh-button"
                        >
                            <Icons name="arrows-repeat" color="rgb(231, 144, 24)"/>
                        </div>
                        <div 
                            style = {{width : `${130 * filter_config.length}px`}}
                            className='filter'
                        >
                        <div 
                            style = {{
                                width : `${100 / filter_config.length}%`, 
                                left : `calc(${(100 / filter_config.length) * filter_config.findIndex(f => f.name === manager.filter) + 1}% - ${filter_config.findIndex(f => f.name === manager.filter) === 0 ? "2.5px" : "6.5px"})`,
                            }}
                            className='selector'></div>
                        {
                            filter_config.map((filter, index:number) => (
                                <div 
                                    style = {{width : `${100 / filter_config.length}%`}}
                                    key={`${filter.name}-0${index}`} 
                                    onClick = {() => setManager((state:any) => {return {...state, filter : filter.name}})}
                                    className={`filter__item filter__item--${filter.name} ${manager.filter === filter.name ? "filter__item--active" : ""}`}>
                                    <p>{`${t(filter.label)}`}</p>
                                    <p>
                                        {
                                            filter.name === "all" ? 
                                                `${invoices.length}`
                                            : 
                                                `${invoices.filter(inv => filter.name === inv.type).length}`
                                        }
                                    </p>
                                </div>
                            ))
                        }

                        </div>
                    </React.Fragment>
                )
            }
        }, 
        filter_custom : (data:any) => {
            if(manager.filter === "all") {
                return data
            }
           return data.filter((dt:any) => invoices.find((_, index:number) => dt[0] === index).type === manager.filter)
        }, 
        handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }

    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des factures en cours..."}))
            props.server.get('/invoices/initialize')
            .then((res:any) => {
                const data_formated = res.data.data.map((sup:any )=> Object.fromEntries(Object.entries(sup).map(([key, value]:[string, any]) => {
                    if(["payment_info","address","contact"].includes(key)) {
                        return [key, JSON.parse(value)]
                    }
                    return [key, value]
                })))

                setManager(state => { return {...state, initialize : true}})
                setInvoices(data_formated)

                //A MODIFIER : PHASE DE TEST
                //setManager(state => {return {...state, selected_data : data_formated[0].id, mode : "detail"}})
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])


    return (
        <React.Fragment>
            <AnimatePresence>
            {
                manager.selected_data !== null ?
                    <InvDetail
                        server = {props.server}
                        id = {manager.selected_data}
                        handleClose = {() => setManager(state => {return{...state, mode : "listing", selected_data : null}})}
                        setData={setInvoices}
                    />
                : 
                    null
            }
            </AnimatePresence>
            <BaseApp>
                <AnimatePresence>
                    {
                        manager.add ?
                            <InvoicesMan 
                                server={props.server}
                                handleRerender = {() => setManager(state => {return{...state, initialize : false, add : false, update : initialize_update}})}
                                handleClose = {() => setManager(state => {return{...state, add : false, update : initialize_update}})}
                            />
                        : 
                            null
                    }
                </AnimatePresence>

                <BaseContainer nameClass = "invoices">
                    <StepsText
                        title = {t("invoices.title")}
                        position = {stepsText_position}
                    />
                    <Table {...table_config} />
                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}