import { useTranslation } from "react-i18next"
import BasePopup from "../../../tools/components/general/BasePopup"
import CategorizerManager from "../../../tools/components/general/Categorizer"
import { useState } from "react"
import { MerchandiseModel } from "../../../database/merchandise-model"
import CharacteristicsMerch from "./layouts/CharacteristicsMerch"
import { ServerProps } from "../../../config/server.config"
import BillingMerch from "./layouts/BillingMerch"
import MonetaryMerch from "./layouts/MonetaryMerch"
import MainButton from "../../../tools/components/general/MainButton"
import { useDispatch } from "react-redux"
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global"
import PicturesMerch from "./layouts/PicturesMerch"
import QRCodeMerch from "./layouts/QRCodeMerch"
import NatureMerch from "./layouts/NatureMerch"
import { AnimatePresence } from "framer-motion"
import { Icons, StringUtils } from "tc-minibox"

export interface MerchandisesManProps extends ServerProps {
    update : MerchandiseModel | null,
    handleClose : () => void, 
    rerender : () => void
}

export default function MerchandisesMan(props:MerchandisesManProps) {
    // * GENERAL VARIABLES
    const {t} = useTranslation()
    const dispatch = useDispatch()

    // * STATES
    const [form, setForm] = useState<MerchandiseModel>(props.update !== null ? props.update : {
        nature : null, 
        id_supplier: null,
        id_inv: null,
        id_family: null,
        raw_materials : [],
        //id_subfamily: null,
        name: '',
        pictures: [],
        type: 'batch',
        unit_rp_amount: 0,
        exchange_rate: 0,
        quantity : 0, 
        material : '', 
        color : "", 
        size : {
            w : '', 
            d : '', 
            h : ''
        }
    })

    //* FUNCTIONS
    const handleSubmit = () => {
        const data = new FormData();

 
        if(form.id_family === null) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-family")}))
        }
        /*if(form.id_subfamily === null) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner une sous-famille"}))
        }*/
        if(form.name === "") {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-name")}))
        }
        if(form.pictures.length === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-pictures")}))
        }

        if(form.nature === "finished_product") {
            if(form.id_supplier === null) {
                return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-supplier")}))
            }
            if(form.id_inv === null) {
                return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-invoice")}))
            }
            data.append("id_supplier", `${form.id_supplier}`);
            data.append('id_inv', `${form.id_inv}`);
            data.append('raw_materials', JSON.stringify([]))
        }
        if(form.nature === 'manufactured_product') {
            if(form.raw_materials.length === 0) {
                return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-raw-materials")}))
            }
            data.append('raw_materials', JSON.stringify(form.raw_materials.map(el => {
                return {
                    id : el.id, 
                    quantity_choosed : el.quantity_choosed
                }
            })))
        }

        if(StringUtils.isUndefinedValue(form.quantity) || parseInt(`${form.quantity}`) === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-quantity")}))
        }

        if(StringUtils.isUndefinedValue(form.unit_rp_amount) || parseInt(`${form.unit_rp_amount}`) === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-unit-rp-amount")}))
        }

        data.append("nature", `${form.nature}`);
        data.append('id_family', `${form.id_family}`);
        //data.append('id_subfamily', `${form.id_subfamily}`);
        data.append('name', `${form.name}`);
        data.append('type', `${form.type}`);
        data.append('unit_rp_amount', `${form.unit_rp_amount}`);
        data.append('exchange_rate', `${form.exchange_rate}`);
        data.append('quantity', `${form.quantity}`);
        data.append('material', `${form.material}`);
        data.append('color', `${form.color}`);
        data.append('size', JSON.stringify(form.size));

        // Ajoutez chaque fichier individuellement au FormData
        for (let i = 0; i < form.pictures.length; i++) {
            data.append('files', form.pictures[i]);
        }
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('merchandises/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            props.rerender()
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }

    //* FUNCTIONS
    const handleUpdate = () => {
        const data = new FormData();

        if(form.id_family === null) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-family")}))
        }
        /*if(form.id_subfamily === null) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner une sous-famille"}))
        }*/
        if(form.name === "") {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-name")}))
        }
        if(form.pictures.length === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-pictures")}))
        }

        if(form.nature === "finished_product") {
            if(form.id_supplier === null) {
                return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-supplier")}))
            }
            if(form.id_inv === null) {
                return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-invoice")}))
            }
            data.append("id_supplier", `${form.id_supplier}`);
            data.append('id_inv', `${form.id_inv}`);
            data.append('raw_materials', JSON.stringify([]))
        }

        if(form.nature === 'manufactured_product') {
            if(form.raw_materials.length === 0) {
                return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-raw-materials")}))
            }
            data.append('raw_materials', JSON.stringify(form.raw_materials.map(el => {
                return {
                    id : el.id, 
                    quantity_choosed : el.quantity_choosed
                }
            })))
        }

        if(StringUtils.isUndefinedValue(form.quantity) || parseInt(`${form.quantity}`) === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-quantity")}))
        }

        if(StringUtils.isUndefinedValue(form.unit_rp_amount) || parseInt(`${form.unit_rp_amount}`) === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-unit-rp-amount")}))
        }




        data.append("id_supplier", `${form.id_supplier}`);
        data.append("nature", `${form.nature}`);
        //data.append('id_subfamily', `${form.id_subfamily}`);
        data.append('name', `${form.name}`);
        data.append('type', `${form.type}`);
        data.append('unit_rp_amount', `${form.unit_rp_amount}`);
        data.append('exchange_rate', `${form.exchange_rate}`);
        data.append('quantity', `${form.quantity}`);
        data.append('material', `${form.material}`);
        data.append('color', `${form.color}`);
        data.append('size', JSON.stringify(form.size));
        data.append('merchandises', JSON.stringify(form.merchandises)); 
        data.append('pictures', JSON.stringify(form.pictures.filter((el:any) => typeof el === "string"))) ; 
        data.append('original', JSON.stringify(props.update))

        // Ajoutez chaque fichier individuellement au FormData
        for (let i = 0; i < form.pictures.length; i++) {
            if(typeof form.pictures[i] !== "string") {
                data.append('files', form.pictures[i]);
            }
        }
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('merchandises/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            props.rerender()
            props.handleClose()
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }

    // * CONFIG COMPONENTS
    const categorizer_config = [
        {
            id : "MOD-01", 
            name : t("merchandises.categorizer.characteristics"), 
            svg : "users", 
            component : <CharacteristicsMerch
                            state = {form} 
                            setState = {setForm}
                            server={props.server}
                        />
        }, 
        {
            id : "MOD-02", 
            svg : "money-bill", 
            name : t("merchandises.categorizer.billing"),
            component : <BillingMerch 
                            state = {form} 
                            setState = {setForm}
                            server={props.server}
                        />
        }, 
        {
            id : "MOD-03", 
            name : t("merchandises.categorizer.monetary"), 
            svg : "users", 
            component : <MonetaryMerch
                            state = {form} 
                            setState = {setForm}
                            server={props.server}
                        />
        }, 
        ...props.update !== null ? [
            {
                id : "MOD-04", 
                name : t("merchandises.categorizer.qrcode"), 
                svg : "barcode-read", 
                component : <QRCodeMerch
                                state = {form} 
                                setState = {setForm}
                            />
            }, 
        ] : []

    ]

    return (
        <BasePopup handleClose = {props.handleClose} nameClass="merchandises-man">
            {
                props.update === null && form.nature !== null ?
                    <div 
                        onClick={() => setForm((state:any) => {return{...state, nature : null}})}
                        className="button-return"
                    >
                        <Icons name = "arrow-turn" mode = "duotone" color = "white" />
                    </div>
                : 
                    null
            }
            <AnimatePresence>
            {
                form.nature === null ?
                    <NatureMerch 
                        state = {form} 
                        setState = {setForm}
                    />
                : 
                    null
            }
            </AnimatePresence>
            <div className = "merchandises-man--identification">
                <div>
                    <label htmlFor = "name">{t("merchandises.table.name")}</label>
                    <input 
                        type = "text" 
                        id = "name" 
                        value = {form.name} 
                        onChange = {(e) => setForm(state => {return{...state, name : e.target.value}})}
                    />
                </div>
            </div>

            <div className="merchandises-man--container">
                <div className="merchandises-man--container__pictures">
                    <PicturesMerch 
                        update = {props.update !== null ? true : false}
                        pictures={form.pictures}
                        setPictures={(data:any) => setForm((state:any) => {return{...state, pictures : data}})}
                    />
                </div>

                <div className="merchandises-man--container__categorizer">
                    <CategorizerManager config = {categorizer_config} rerender = {true} />


                    {
                        props.update !== null ?
                            JSON.stringify(form) !== JSON.stringify(props.update) ?
                                <MainButton
                                    handleClick={() => handleUpdate()}
                                >
                                    Modifier
                                </MainButton>
                            : 
                                null
                        : 
                            <MainButton
                            handleClick={() => handleSubmit()}
                            >
                                {t("commons.save")}
                            </MainButton>
                            
                    }

                </div>
            </div>
        </BasePopup>
    )
}


