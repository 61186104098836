import { Table } from "tc-minibox"
import { icons_config } from "../../../../config/context.config"
import { TableForProps } from "tc-minibox/dist/types/table/index.table"
import { useTranslation } from "react-i18next"
import { hostname } from "../../../../config/server.config"
import { removeDuplicates } from "../../../merchandises/Merchandises"


export interface AssociatedMerchInvDetailProps {
    merchandises : any,
    setMerchandises : React.Dispatch<any>
}

export default function AssociatedMerchInvDetail(props: AssociatedMerchInvDetailProps) {
        // * GENERAL VARIABLES 
        const {t} = useTranslation()
        const translate = (key:string) => t(`invoices.detail.${key}`)

        // * VARIABLES
        const pre_tbody = props.merchandises.map((dt:any) => {
            const decomposed_code = dt.code.split('-')
            return {
                ...dt, 
                size_text : `${dt.size.w} x ${dt.size.d} x ${dt.size.h}`, 
                qr_supplier : decomposed_code[0], 
                qr_code : decomposed_code[1], 
                qr_batch : decomposed_code[2], 
                pictures : JSON.parse(dt.pictures)
            }
        })

        const getUnique = pre_tbody
                            .filter((el:any) => pre_tbody.filter((pt:any) => `${pt.qr_supplier}-${pt.qr_code}` === `${el.qr_supplier}-${el.qr_code}`).length === 1)
                            .map((el:any) => {
                                return {
                                    ...el, 
                                    total : `${parseFloat(el.unit_rp_amount) * parseFloat(el.quantity)} IDR`,
                                    code : `${el.qr_supplier}-${el.qr_code}`,
                                    cost_price : parseFloat(el.unit_rp_amount) * parseFloat(el.quantity) * parseFloat(el.exchange_rate),
                                    
                                }
                            })
        const batched_lines = pre_tbody.filter((el:any) => pre_tbody.filter((pt:any) => `${pt.qr_supplier}-${pt.qr_code}` === `${el.qr_supplier}-${el.qr_code}`).length > 1)
        const getBatch = removeDuplicates(batched_lines).map((dt:any) => {
            const quantity = batched_lines.filter((bl:any) => `${bl.qr_supplier}-${bl.qr_code}` === `${dt.qr_supplier}-${dt.qr_code}`).length
            return {
                ...dt, 
                code : `${dt.qr_supplier}-${dt.qr_code}`,
                quantity : quantity, 
                batched : true, 
                cost_price : `${(parseFloat(dt.unit_rp_amount) * quantity * parseFloat(dt.exchange_rate)).toFixed(2)}€`,
                total : `${parseFloat(dt.unit_rp_amount) * parseFloat(quantity)} IDR`,
            }
        })
        const tbody_merchandises = [
            ...getUnique, 
            ...getBatch
        ].map(el => {
            return {
                ...el, 
                unit_rp_amount : `${parseFloat(el.unit_rp_amount).toFixed(2)} IDR`,
                exchange_rate : `${parseFloat(el.exchange_rate).toFixed(2)} IDR`,
                cost_price : `${((parseFloat(el.unit_rp_amount) * parseFloat(el.quantity)) / parseFloat(el.exchange_rate)).toFixed(3)}€`,
            }
        })
        /*const tbody_merchandises = props.merchandises.map((merch:any) => {
            return {
                ...merch, 
                amount : `${merch.unit_rp_amount.toFixed(2)} IDR`,
                pictures : JSON.parse(merch.pictures)
            }
        })*/

        // * COMPONENTS CONFIG
        const table_merchandises_config:TableForProps = {
            height : 80, 
            editing : false, 
            icons : icons_config, 
            config : [
                {
                    info : {
                        text : translate("photo"), 
                        name : "photo", 
                        custom : (tr) => {
    
                            return (
                                <div className = "photo">
                                    <img src = {`${hostname}/${tr.pictures[0]}`} alt = {`IMG ${tr.code}`}/>
                                </div>
                            )
                        }
                    }, 
                    style : {
                        width : "200px",
                    }
                },
                {
                    info : {
                        text : "Code", 
                        name : "code", 
                    }, 
        
                    style : {
                        width : "150px",
                    }
                },
                {
                    info : {
                        text : translate("name"), 
                        name : "name"
                    }, 
                    style : {
                        width : `calc(100% - 670px)`, 
                        tbody : {
                            textAlign : "center"
                        }
                    }
                },  
                {
                    info : {
                        text : t('merchandises.table.quantity'), 
                        name : "quantity", 
                    }, 
                    style : {
                        width : "60px",
                        tbody : {
                            textAlign : "center"            
                        }
                    }
                },
                {
                    info : {
                        text : t('merchandises.table.unit_rp_amount'), 
                        name : "unit_rp_amount", 
                    }, 
                    style : {
                        width : "120px",
                        tbody : {
                            textAlign : "end", 
                            
                        }
                    }
                },
                {
                    info : {
                        text : 'Total', 
                        name : "total", 
                    }, 
                    style : {
                        width : "140px",
                        tbody : {
                            textAlign : "end"
                        }
                    }
                },
            ],
            data : tbody_merchandises,
            setData : props.setMerchandises,
            header : {
                buttons : {
                    include : [/*"add" , "delete"*/], 
                    actions : {
                        /*add: () => setManager(state => {return {...state, add : true}}), 
                        delete : (e:any) => {
                            dispatch(set_popup_warning({
                                status : true, 
                                text : "Êtes-vous sûr de vouloir supprimer ?", 
                                handleYes : () => handleDelete(e)
                            }))
    
                        }, */
    
                    }
                }
            }, 
            //handleDoubleClick : (tr:any) => handleDoubleClick(tr)
        }
    
    return (
        <div className="inv-detail__associated-merch">
            <Table {...table_merchandises_config} />
        </div>
    )
}