import React, { useState } from "react";
import { ServerProps } from "../../../../config/server.config";
import BasePopup from "../../../../tools/components/general/BasePopup";
import ImportModule, { ImportProps } from "../../../../tools/components/general/ImportModule";
import { Icons } from "tc-minibox";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../../store/global/actions.global";
import MainButton from "../../../../tools/components/general/MainButton";


export interface UpdateFileProps extends ServerProps {
    handleClose : () => void, 
    id_inv : any, 
    handleReloadFile : () => void, 
    setUrl : (url:string) => void
}

type UpdateFileManager = {
    file : any
}

export default function UpdateFile(props: UpdateFileProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const [manager, setManager] = useState<UpdateFileManager>({
        file : {}
    })

    // * CONFIG COMPONENTS
    const import_config:ImportProps = {
        subtitle : t("invoices.manager.text-import"),
        nameClass : 'new-affair--import', 
        handleSubmit : (file:File) => {
            const mimetypes_accepted = [
                "image/jpeg",
                "image/png",
                "application/pdf",
            ];
            
            
            if(!mimetypes_accepted.includes(file.type)) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : t("statusRequest.error-file-format")
                }))
            }
            const fileURL = URL.createObjectURL(file);
            setManager({...manager, file : file});

            //dispatch(setLoaderStatus({status : true, message : "Traitement du fichier en cours..."}))



        }
    }

    const handleSubmit = () => {
        const container = new FormData();
        container.append('id_inv', props.id_inv);
        container.append('file', manager.file as File);

        dispatch(setLoaderStatus({status : true}))

        props.server.post('/invoices/update-inv-file', container, { headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            props.handleReloadFile();
            props.handleClose();
            props.setUrl(res.data.data)
            
            dispatch(addRequestStatus({
                status : true, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }
    return (
        <BasePopup
            nameClass="inv-update-file"
            handleClose = {() => props.handleClose()}
        >
            {
                manager.file instanceof File ? 
                <div>
                    <h1>Your invoice</h1>
                    <div className="download-file">
                        <a href={URL.createObjectURL(manager.file)} target="_blank" rel="noreferrer">
                            <Icons name = "file-invoice-dollar" mode = "duotone" color = "#F3A73B" />
                            <p>{manager.file.name}</p>
                        </a>
                    </div>

                    <MainButton 
                        handleClick={handleSubmit}
                    >
                        Submit
                    </MainButton>
                </div>
            : 
                <React.Fragment>
                    <label htmlFor="file">{t("invoices.manager.myinvoice")}</label>
                    <ImportModule {...import_config} />
                </React.Fragment>
            }
        </BasePopup>
    )
}