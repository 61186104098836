import { ServerProps } from "../../../config/server.config";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useTranslation } from "react-i18next";

export interface ChartTransactionsProps extends ServerProps  {
  identification : string, 
  chartLabels : any[],
  dailySpends: number[],
  totalDeposits: number[],
}

export default function ChartTransactions(props:ChartTransactionsProps) {
  const {t} = useTranslation()
  const translate = (key:string) => t(`transactions.${key}`)
    /*const dailySpends: number[] = [
        500, 320, 450, 700, 300, 500, 250, 600, 350, 400,
        500, 320, 450, 700, 300, 500, 250, 600, 350, 400,
        500, 320, 450, 700, 300, 500, 250, 600, 350, 400
      ];
      
    // Montant total de dépôt pour chaque jour (en raw)
    const totalDeposits: number[] = [
        9500, 9180, 8730, 8030, 7730, 7230, 6980, 6380, 6030, 5630,
        5130, 4810, 4360, 3660, 3360, 2860, 2610, 2010, 1660, 1260,
        760, 440, -90, -790, -1090, -1590, -1840, -2440, -2790, -3190
    ];*/
    
    // Labels pour chaque jour du mois
    //const daysInMonth: string[] = Array.from({ length: 30 }, (v, k) => (k + 1).toString());


    const data: ChartData<'line'> = {
        labels: props.chartLabels,
        datasets: [
          {
            label: translate("deposit"),
            data: props.totalDeposits,
            fill: false,
            borderColor: '#19ba00',
            backgroundColor : "#19ba0042", 
            tension: 0.3
          }, 
          {
            label: translate("withdrawal"),
            data: props.dailySpends,
            fill: false,
            borderColor: '#C3281B',
            backgroundColor : "#c3281b45", 
            tension: 0.1
          }
        ]
      };
    
      const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio : false, 
        scales: {
          y: {
            beginAtZero: false
          }
        }
      };



      Chart.register(zoomPlugin);



    return (
        <div id="chart">
            <Line key = {props.identification} data={data} options={options} />
        </div>
    )
}