import { useTranslation } from "react-i18next"
import BasePopup from "../../../tools/components/general/BasePopup"
import CategorizerManager from "../../../tools/components/general/Categorizer"
import { useState } from "react"
import { ServerProps } from "../../../config/server.config"
import BillingRawMat from "./layouts/BillingRawMat"
import MonetaryRawMat from "./layouts/MonetaryRawMat"
import MainButton from "../../../tools/components/general/MainButton"
import { useDispatch } from "react-redux"
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global"
import PicturesRawMat from "./layouts/PicturesRawMat"
import { RawMaterialModel } from "../../../database/raw_materials-model"

export interface RawMaterialsManProps extends ServerProps {
    update : RawMaterialModel | null,
    handleClose : () => void, 
    rerender : () => void
}

export default function RawMaterialsMan(props:RawMaterialsManProps) {
    // * GENERAL VARIABLES
    const {t} = useTranslation()
    const dispatch = useDispatch()

    // * STATES
    const [form, setForm] = useState<RawMaterialModel>(props.update !== null ? props.update : {
        id_supplier: null,
        id_inv: null,
        //id_subfamily: null,
        name: '',
        pictures: [],
        type: 'batch',
        unit_rp_amount: 0,
        exchange_rate: 0,
        quantity : 0, 
    })


    //* FUNCTIONS
    const handleSubmit = () => {
        if(form.id_supplier === null) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-supplier")}))
        }
        if(form.id_inv === null) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-invoice")}))
        }
        if(form.name === "") {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-name")}))
        }
        /*
        if(form.pictures.length === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-pictures")}))
        }*/



        const data = new FormData();
        data.append("id_supplier", `${form.id_supplier}`);
        data.append('id_inv', `${form.id_inv}`);
        //data.append('id_subfamily', `${form.id_subfamily}`);
        data.append('name', `${form.name}`);
        data.append('type', `${form.type}`);
        data.append('unit_rp_amount', `${form.unit_rp_amount}`);
        data.append('exchange_rate', `${form.exchange_rate}`);
        data.append('quantity', `${form.quantity}`);

        // Ajoutez chaque fichier individuellement au FormData
        for (let i = 0; i < form.pictures.length; i++) {
            data.append('files', form.pictures[i]);
        }
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('raw_materials/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            props.rerender()
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }

    //* FUNCTIONS
    const handleUpdate = () => {
        if(form.id_supplier === null) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-supplier")}))
        }
        if(form.id_inv === null) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-invoice")}))
        }
        /*if(form.id_subfamily === null) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner une sous-famille"}))
        }*/
        if(form.name === "") {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.enter-name")}))
        }
        /*if(form.pictures.length === 0) {
            return dispatch(addRequestStatus({status : false, message : t("merchandises.status.select-pictures")}))
        }*/



        const data = new FormData();
        data.append("id", `${form.id}`);
        data.append("code", `${form.code}`)
        data.append("id_supplier", `${form.id_supplier}`);
        data.append('id_inv', `${form.id_inv}`);
        //data.append('id_subfamily', `${form.id_subfamily}`);
        data.append('name', `${form.name}`);
        data.append('type', `${form.type}`);
        data.append('unit_rp_amount', `${form.unit_rp_amount}`);
        data.append('exchange_rate', `${form.exchange_rate}`);
        data.append('quantity', `${form.quantity}`);
        data.append('pictures', JSON.stringify(form.pictures.filter((el:any) => typeof el === "string"))) ; 
        data.append('original', JSON.stringify(props.update))

        // Ajoutez chaque fichier individuellement au FormData
        for (let i = 0; i < form.pictures.length; i++) {
            if(typeof form.pictures[i] !== "string") {
                data.append('files', form.pictures[i]);
            }
        }
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('raw_materials/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            props.rerender()
            props.handleClose()
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }



    // * CONFIG COMPONENTS
    const categorizer_config = [
        {
            id : "MOD-01", 
            svg : "money-bill", 
            name : t("merchandises.categorizer.billing"),
            component : <BillingRawMat 
                            state = {form} 
                            setState = {setForm}
                            server={props.server}
                        />
        }, 
        {
            id : "MOD-02", 
            name : t("merchandises.categorizer.monetary"), 
            svg : "users", 
            component : <MonetaryRawMat
                            update = {props.update}
                            state = {form} 
                            setState = {setForm}
                            server={props.server}
                        />
        }, 
    ]

    return (
        <BasePopup handleClose = {props.handleClose} nameClass="merchandises-man raw_materials-man">
            <div className = "merchandises-man--identification">
                <div>
                    <label htmlFor = "name">{t("merchandises.table.name")}</label>
                    <input 
                        type = "text" 
                        id = "name" 
                        value = {form.name} 
                        onChange = {(e) => setForm(state => {return{...state, name : e.target.value}})}
                    />
                </div>
            </div>

            <div className="merchandises-man--container">
                <div className="merchandises-man--container__pictures">
                    <PicturesRawMat
                        update = {props.update !== null ? true : false}
                        pictures={form.pictures}
                        setPictures={(data:any) => setForm((state:any) => {return{...state, pictures : data}})}
                    />
                </div>

                <div className="merchandises-man--container__categorizer">
                    <CategorizerManager config = {categorizer_config} rerender = {true} />


                    {
                        props.update !== null ?
                            JSON.stringify(form) !== JSON.stringify(props.update) ?
                                <MainButton
                                    handleClick={() => handleUpdate()}
                                >
                                    Modifier
                                </MainButton>
                            : 
                                null
                        : 
                            <MainButton
                            handleClick={() => handleSubmit()}
                            >
                                Enregistrer
                            </MainButton>
                            
                    }

                </div>
            </div>
        </BasePopup>
    )
}


