


type FilterType = {
    name: 'manufactured_product' | 'finished_product';
    label: string;
};

export const merchandises_filters:FilterType[] =  [
    {name: 'manufactured_product', label: 'merchandises.table.manufactured_product'},
    {name: 'finished_product', label: 'merchandises.table.finished_product'},
]



export const identification_thead:any = [
    {
        info : {
            text : 'merchandises.table.family', 
            name : "family.name", 
        }, 
        style : {
            width : "150px",
        }
    }, 
    {
        info : {
            text : 'merchandises.table.supplier', 
            name : "supplier.name", 
        }, 
        style : {
            width : "150px",
        }
    }, 
    {
        info : {
            text : 'merchandises.table.invoice', 
            name : "invoice.inv_number", 
        }, 
        style : {
            width : "150px",
        }
    },
]

export const characteristic_thead:any = [
    {
        info : {
            text : 'merchandises.table.material', 
            name : "material", 
        }, 
        style : {
            width : "200px",
        }
    },
    {
        info : {
            text : 'merchandises.table.color', 
            name : "color", 
        }, 
        style : {
            width : "200px",
        }
    },
    {
        info : {
            text : 'merchandises.table.size', 
            name : "size_text", 
        }, 
        style : {
            width : "200px",
        }
    },
]

export const monetary_thead:any = [
    {
        info : {
            text : 'merchandises.table.quantity', 
            name : "quantity", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "center"            
            }
        }
    },
    {
        info : {
            text : 'merchandises.table.unit_rp_amount', 
            name : "unit_rp_amount", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "end", 
                
            }
        }
    },
    {
        info : {
            text : 'Total', 
            name : "total", 
        }, 
        style : {
            width : "140px",
            tbody : {
                textAlign : "end"
            }
        }
    },
    {
        info : {
            text : 'merchandises.table.exchange_rate', 
            name : "exchange_rate", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "end"
            }
        }
    },
    {
        info : {
            text : 'merchandises.table.cost_price', 
            name : "cost_price", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "end"
            }
        }
    },
]

export const theads_merchandises:any = [
    {
        id : 'THEADS-MERCH-01', 
        title : "merchandises.views.identification", 
        width : "350px", 
        svg : "home", 
        content : identification_thead
    }, 
    {
        id : 'THEADS-MERCH-02', 
        title : "merchandises.views.characteristics", 
        width : "600px", 
        svg : "users", 
        content : characteristic_thead
    }, 
    {
        id : 'THEADS-MERCH-03', 
        title : "merchandises.views.monetary", 
        svg : "money-bill", 
        width : "620px", 
        content : monetary_thead
    }
]