import { Axios } from 'axios';
import { AuthType } from '../../store/types.store';
import Homepage from '../base/Homepage';
import Merchandises from '../merchandises/Merchandises';
import Suppliers from '../suppliers/Suppliers';
import Invoices from '../invoices/Invoices';
import Transactions from '../transactions/Transactions';
import RawMaterials from '../raw_materials/RawMaterials';

export interface NavigationProps {
    server : Axios, 
    lang? : any
}

export interface NavigationParams extends NavigationProps {
    account : AuthType
}

type NavigationItem = {
    id : string,
    id_menu : number,
    content : string,
    svg : string,
    path : string,
    component ?: JSX.Element, 
}

export const navigation = (props:NavigationProps):NavigationItem[] => {

    return [
        {id : 'N-00', id_menu : 0, content : props.lang === "en" ? "Dashboard" : "Tableau de bord", svg : "home", path : '/', component : <Homepage {...props}/>}, 
        {id : 'N-01', id_menu : 1, content : props.lang === "en" ? "Raw materials" : "Matières premières", svg : "leaf", path : '/raw_materials', component : <RawMaterials {...props}/>}, 
        {id : 'N-02', id_menu : 2, content : props.lang === "en" ? "Merchandises" : "Marchandises", svg : "archive", path : '/merchandises', component : <Merchandises {...props} />}, 
        {id : 'N-03', id_menu : 3, content : props.lang === "en" ? "Suppliers" : "Fournisseurs", svg : "truck-fast", path : '/suppliers', component : <Suppliers {...props} />}, 
        {id : 'N-04', id_menu : 4, content : props.lang === "en" ? "Invoices" : "Facturation", svg : "file-invoice-dollar", path : '/invoices', component : <Invoices {...props} />},
        {id : 'N-05', id_menu : 5, content : props.lang === "en" ? "Transactions" : "Transactions", svg : "money-bill", path : '/transactions', component : <Transactions {...props} />},
    ]
}

export const navigation_data = (props:NavigationParams) => {
    /*if(props.account.infos.user_group === "direction") {
        return navigation({server : props.server})
    }*/
    // ! A CONFIGURER
    //const access_id = props.account.infos.access.map((acc:any) => acc.id_menu)
    //return navigation({server : props.server}).filter(nav => [1, ...access_id].includes(nav.id_menu) )

    return navigation({server : props.server, lang : props.lang})
}