

export const swap_modes = [
    {
        name : "bank_transfer", 
        text : "bank_transfer"
    }, 
    {
        name : "withdrawal", 
        text : "withdrawal"
    }
]


export const date_swap = [
    {
        name : "all", 
        text : "all"
    }, 
    {
        name : "year", 
        text : "year"
    }, 
    {
        name : "month", 
        text : "month"
    }, 
]

export function generateRandomCode(prefix:string) {
    const prefix_final = prefix.replaceAll('_', '').slice(0,6)
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    let randomString = '';
    for (let i = 0; i < 5; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return `${prefix_final}-${randomString}`;
  }


export function getDataByDay(transactions: any[], type: string, year: number, month: number, mode: 'all' | 'year' | 'month') {
    // Function to get the number of days in a given month
    const getDaysInMonth = (year: number, month: number) => new Date(year, month + 1, 0).getDate();

    // Initialize an accumulator object
    const acc: Record<string, any[]> = {};

    // Depending on the mode, initialize the accumulator with different keys
    if (mode === 'month') {
        // Initialize keys for each day of the specified month
        const daysInMonth = getDaysInMonth(year, month);
        for (let day = 1; day <= daysInMonth; day++) {
            const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            acc[dateKey] = [];
        }
    } else if (mode === 'year') {
        // Initialize keys for each month of the specified year
        for (let month = 0; month < 12; month++) {
            const dateKey = `${year}-${String(month + 1).padStart(2, '0')}`;
            acc[dateKey] = [];
        }
    } else { // mode === 'all'
        // Initialize keys for each year present in the transactions
        transactions.forEach((transaction: any) => {
            const transactionYear = transaction.date.split('-')[0];
            if (!acc.hasOwnProperty(transactionYear)) {
                acc[transactionYear] = [];
            }
        });
    }

    // Reduce the transactions into the accumulator
    transactions.forEach((transaction: any) => {
        if (transaction.type === type) {
            const date = transaction.date.split('T')[0];
            const transactionYear = date.split('-')[0];
            const transactionMonth = date.split('-')[1];
            let dateKey: string;

            // Construct the key based on the mode
            if (mode === 'month') {
                dateKey = date;
            } else if (mode === 'year') {
                dateKey = `${transactionYear}-${transactionMonth}`;
            } else { // mode === 'all'
                dateKey = transactionYear;
            }

            // If the key exists in the accumulator, push the transaction into the array
            if (acc.hasOwnProperty(dateKey)) {
                acc[dateKey].push(transaction);
            }
        }
    });

    // The object now contains arrays sorted by the specified mode
    return acc;
}

export function getChartLabels(transactions: any[], mode: 'all' | 'year' | 'month', year?: number, month?: number): string[] {
    // Obtenir le nombre de jours dans un mois donné
    const getDaysInMonth = (year: number, month: number) => new Date(year, month + 1, 0).getDate();
    
    let labels: string[] = [];
  
    if (mode === 'month' && year !== undefined && month !== undefined) {
      // Générer des labels pour chaque jour du mois spécifié
      const daysInMonth = getDaysInMonth(year, month);
      labels = Array.from({ length: daysInMonth }, (_, k) => `${String(k + 1).padStart(2, '0')}`);
    } else if (mode === 'year' && year !== undefined) {
      // Générer des labels pour chaque mois de l'année spécifiée
      labels = Array.from({ length: 12 }, (_, k) => `${year}-${String(k + 1).padStart(2, '0')}`);
    } else if (mode === 'all') {
      // Créer un Set pour s'assurer que les labels sont uniques et triés
      const yearsSet = new Set<string>();
      transactions.forEach(transaction => {
        const transactionYear = transaction.date.split('-')[0];
        yearsSet.add(transactionYear);
      });
      // Convertir le Set en tableau trié
      labels = Array.from(yearsSet).sort();
    }
  
    return labels;
  }

  

  export function getMonthName(date: Date, lang: 'en' | 'fr' = 'fr'): string {
    const monthNames = {
      en: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      fr: [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
      ]
    };
  
    return monthNames[lang][date.getMonth()];
  }
