import { compagny, icons_config } from '../../config/context.config';
import {motion} from 'framer-motion' ; 
import {Link, useLocation} from 'react-router-dom'
import { useSelector } from "react-redux";
import { account_user } from '../../store/account/selector.account';
import { navigation_data } from './data.nav';
import { ServerProps } from '../../config/server.config';
import { Icons } from 'tc-minibox';
import { useTranslation } from 'react-i18next';




export default function Navigation(props:ServerProps) {
    const account = useSelector(account_user)
    const location = useLocation()
    const {i18n} = useTranslation()
    

    return (
        <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            className="navigation">

            {/* /// LOGO /// */}
            <div className="logo">
                <div className="normal">{compagny.logo}</div>
                <div className="minified">
                    <img src = {compagny.logo_min} alt = "Logo minified"/>
                </div>
            </div>

            {/* /// LISTING NAVIGATION /// */}
            <div 
                className="navigation__container"
            >
                {
                    navigation_data({account : account, server : props.server, lang : i18n.language}).map(el => (
                        <div 
                            key = {el.id}
                        >
                            <Link className={location.pathname === el.path ? "active" : ""} to = {el.path} >
                                <Icons name = {el.svg} mode = "duotone" color = {location.pathname === el.path ? "white" : icons_config.color}/>
                                <p><span>{el.content}</span></p>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </motion.div>
    )
}
