/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ServerProps, hostname } from "../../../config/server.config";
import BasePopup from "../../../tools/components/general/BasePopup";
import CategorizerManager from "../../../tools/components/general/Categorizer";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import ContainerLoad from "../../../tools/components/general/ContainerLoad";
import GeneralitiesInvDetail from "./layouts/GeneralitiesInvDetail";
import PaymentsInvDetail from "./layouts/PaymentsInvDetail";
import { Icons } from "tc-minibox";
import { useTranslation } from "react-i18next";
import AssociatedMerchInvDetail from "./layouts/AssociatedMerchInvDetail";
import AssociatedRawMatInvDetail from "./layouts/AssociatedRawMatInvDetail";
import UpdateFile from "./layouts/UpdateFile";

export interface InvDetailProps extends ServerProps {
    handleClose : () => void, 
    id : any, 
    setData : React.Dispatch<React.SetStateAction<any>>
}

export interface InvDetailManager {
    initialize_inv : boolean, 
    initialize_trans : boolean, 
    mode : "load" | "loaded", 
    update_file : boolean

}


export default function InvDetail(props:InvDetailProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const translate = (key:string) => t(`invoices.detail.${key}`)

    // * STATES
    const [IframeKey, setIframeKey] = useState<any>(Date.now())
    const [transactions, setTransactions] = useState<any>([])
    const [invoice, setInvoice] = useState<any>({})
    const [manager, setManager] = useState<InvDetailManager>({
        initialize_inv : false, 
        initialize_trans : false, 
        mode : "load", 
        update_file : false
    })

    // * VARIABLES 
    const amount_paid = transactions.map((trans:any) => trans.amount * trans.exchange_rate).reduce((a:number, b:number) => a + b, 0)
    const average_exchange_rate = transactions.map((trans:any) => trans.exchange_rate).reduce((a:number, b:number) => a + b, 0) / transactions.length

    // * FUNCTIONS 
    const handleReloadIframe = () => {
        setIframeKey(Date.now())
    }
    // * COMPONENT CONSTRUCTOR
    const categorizer_config = [
        {
            id : "MOD-00", 
            name : translate("generalities"),
            svg : "home", 
            component : <GeneralitiesInvDetail 
                            server={props.server}
                            invoice = {invoice}
                            setInvoice = {setInvoice}
                            transactions={transactions}
                            setTransactions={setTransactions}
                            rerender={() => setManager((state:any) => {
                                return {
                                    ...state,
                                    initialize_inv : false
                            }})}
                        />
        }, 
        {
            id : "MOD-01", 
            name : translate('payments'), 
            svg : "users", 
            component : <PaymentsInvDetail 
                            server = {props.server}
                            invoice = {invoice}
                            setInvoice = {setInvoice}
                            transactions = {transactions}
                            setTransactions = {setTransactions}
                            manager={manager}
                            setManager={setManager}
                            handleReload={() => {
                                setManager((state:any) => {
                                    return {
                                        ...state, 
                                        initialize_trans : false, 
                                        initialize_inv : false, 
                                    }
                                })
                            }}
                        />
        }, 

        {
            id : "MOD-02", 
            svg : "money-bill", 
            name : invoice.type === "finished_products" ? translate("finished-products") : translate("raw-materials"),

            component : invoice.type === "finished_products" ?  <AssociatedMerchInvDetail 
                            merchandises={invoice.merchandises}
                            setMerchandises={(e:any) => {
                                setInvoice((state:any) => {
                                    return {
                                        ...state, 
                                        merchandises : e
                                    }
                                })
                            }}
                        />
                        : 
                        <AssociatedRawMatInvDetail
                            merchandises={invoice.merchandises}
                            setMerchandises={(e:any) => {
                                setInvoice((state:any) => {
                                    return {
                                        ...state, 
                                        merchandises : e
                                    }
                                })
                            }}
                        />
        }, 
    ]

    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize_inv) {
            dispatch(setLoaderStatus({status : true, message : "Chargement de la facture en cours..."}))
            props.server.post('/invoices/get', {id : props.id})
            .then((res:any) => {
                setInvoice(res.data.data)
                setManager(state => { return {...state, initialize_inv : true, mode : "loaded"}})
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize_inv])

    useEffect(() => {
        if(!manager.initialize_trans) {
                if(manager.initialize_inv) {
                dispatch(setLoaderStatus({status : true, message : "Chargement de la facture en cours..."}))
                props.server.post('/transactions/get-invoice-payments', {id_inv : invoice.id})
                .then((res:any) => {
                    setTransactions(res.data.data)
                    setManager(state => {return {...state, initialize_trans : true}})
                    dispatch(setLoaderStatus({status : false, message : ""}))
                })
                .catch(err => {
                      dispatch(addRequestStatus({
                        status : err.response.data.status, 
                        message : t(err.response.data.message)
                    
                }))
                    dispatch(setLoaderStatus({status : false}))
                })
            }
        }

        
    }, [manager])

    return (
        <React.Fragment>
            {
                manager.update_file ? 
                    <UpdateFile 
                        server = {props.server}
                        id_inv = {invoice.id}
                        setUrl={(url:string) => {
                            setInvoice((state:any) => {
                                return {
                                    ...state, 
                                    url : url
                                }
                            })
                            props.setData((state:any) => {
                                if(state.id === invoice.id) {
                                    return {
                                        ...state, 
                                        url : url
                                    }
                                }
                                return state
                            })
                        }}
                        handleClose={() => setManager((state:any) => {
                            return {
                                ...state, 
                                update_file : false
                            }
                        })}
                        handleReloadFile={handleReloadIframe}
                    />
                : 
                    null
            }
            <BasePopup 
                handleClose = {props.handleClose}
                nameClass="inv-detail"
            >
                <ContainerLoad load = {manager.mode === "load"}>
                    <div className="inv-detail__header">
                        <h1>{`${translate("invoice")} ${invoice.inv_number} - ${invoice["supplier.name"]}`}</h1>
                        <div className="inv-detail__header--status">
                            <div className="invoice--merchandises">
                            {`${(invoice.merchandises || []).length} ${(invoice.merchandises || []).length > 1 ? translate("merchandises") : translate("merchandise")}`}
                            </div>
                            {
                                transactions.length !== 0 ?
                                    <div 
                                        className={`invoice--exchange-rate invoice--exchange-rate--${(invoice.rp_amount - amount_paid) === 0 ? "lock" : "clock"}`}
                                    >
                                        <Icons name = {(invoice.rp_amount - amount_paid) === 0 ? "lock" : "clock"} mode = "plain" color = "#393939"  />
                                        <p>{`${translate("average_rate")} : ${average_exchange_rate.toFixed(2)} IDR`}</p>
                                        <div className={`invoice--exchange-rate--status invoice--exchange-rate--status--${(invoice.rp_amount - amount_paid) === 0 ? "paid" : "unpaid"}`}>{`${(invoice.rp_amount - amount_paid) === 0 ? t("invoices.status.definitive-rate") : t("invoices.status.temporary-rate") }`}</div>
                                    </div>
                                : 
                                    null
                            }

                            <div 
                                className={`invoice--payment ${amount_paid === invoice.rp_amount ? "invoice--payment--paid" : ""}`}
                            >
                            {
                                (invoice.rp_amount - amount_paid) === 0 ? 
                                    translate("paid")
                                : 
                                    `${(invoice.rp_amount - amount_paid).toFixed(2)} IDR ${translate("to_pay")}`
                            } 
                            </div>

                        </div>
                    </div>
                    <div className="inv-detail__container">
                        <div id = "categorizer">
                            <CategorizerManager 
                                config = {categorizer_config} 
                                rerender = {true} 
                                animation={{
                                    container : {
                                        initial: {
                                        x: "-100px",
                                        opacity: 0,
                                        },
                                        animate: {
                                        x: "0px",
                                        opacity: 1,
                                        },
                                        exit: {
                                        x: "100px",
                                        opacity: 0,

                                        },
                                    }
                                }}
                            />
                        </div>

                        <div id="viewer">
                            <div id="viewer--update-file">
                                <button
                                    onClick = {() => setManager((state:any) => {
                                        return {
                                            ...state, 
                                            update_file : true
                                        }
                                    })}
                                >
                                    Update file
                                </button>
                            </div>
                            {/\.jpe?g$|\.png$|\.gif$|\.bmp$|\.webp$/i.test(invoice.url) ? (
                                <img key={IframeKey} src={`${hostname}/${invoice.url}`} alt="Invoice Preview" />
                            ) : (
                                <iframe key={IframeKey} src={`${hostname}/${invoice.url}`} title="preview" />
                            )}
                        </div>

                    </div>


                </ContainerLoad>
            </BasePopup>
        </React.Fragment>

    )
}