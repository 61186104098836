import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { DateTimeUtils, Icons } from 'tc-minibox';
import { account_user } from '../../store/account/selector.account';
import { MainManager } from '../Main';
import { addRequestStatus, setLoaderStatus } from '../../store/global/actions.global';
import { logout } from '../../store/account/actions.account';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FrenchFlag } from '../../assets/svg/icons/french_flag.svg';
import { ReactComponent as EnglishFlag } from '../../assets/svg/icons/english_flag.svg';

export interface InfoAccountProps {
  setManager: React.Dispatch<React.SetStateAction<MainManager>>;
}

export const languages = [
  {
    name: 'en',
    text: 'En',
    svg: <EnglishFlag />,
  },
  {
    name: 'fr',
    text: 'Fr',
    svg: <FrenchFlag />,
  },
];

export default function InfoAccount(props: InfoAccountProps) {
  const dispatch = useDispatch();
  const user_account = useSelector(account_user);
  const [currentDateTime, setCurrentDateTime] = useState(DateTimeUtils.getCurrentDateTime());

  const { i18n } = useTranslation();
  const i18n_language = i18n.language;
  const current_language = languages.find((lang) => lang.name === i18n_language);

  const handleLogout = () => {
    dispatch(
      addRequestStatus({
        status: true,
        message: 'Vous êtes déconnecté avec succès.',
      })
    );
    dispatch(logout());

    dispatch(setLoaderStatus({ status: true, message: 'Redirection en cours...' }));
    setTimeout(() => {
      window.location.href = 'https://mynyomi.fr';
    }, 5000);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setCurrentDateTime({
        ...currentDateTime,
        date: getFormattedDate(),
        time: new Date().toLocaleTimeString(lang === 'fr' ? 'fr-FR' : 'en-US'),
      });
  };

  const getFormattedDate = () => {
    const date = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const locale = i18n_language === 'fr' ? 'fr-FR' : 'en-US';
    return date.toLocaleDateString(locale, options as any);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime({
        ...currentDateTime,
        date: getFormattedDate(),
        time: new Date().toLocaleTimeString(i18n_language === 'fr' ? 'fr-FR' : 'en-US'),
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [i18n_language, currentDateTime]);

  return (
    <motion.div
      animate={{ translateY: ['-100%', '0%'], transition: { duration: 0.5, delay: 0.5 } }}
      className='info-account'
    >
      <div className='datetime'>
        <p>{currentDateTime.date}</p>
        <p>{currentDateTime.time}</p>
      </div>
      <div className='name'>{`${user_account.infos.firstname ?? ''} ${user_account.infos.lastname ?? ''}`}</div>
      <div
        onClick={() => changeLanguage(current_language?.name === 'fr' ? 'en' : 'fr')}
        className='language'
      >
        <div id={current_language?.name}>
          <p>{current_language?.text}</p>
          <div>{current_language?.svg}</div>
        </div>
      </div>
      <div className='buttons'>
        <motion.div onClick={handleLogout}>
          <Icons name='right-from-bracket' mode='duotone' color='red' />
        </motion.div>
      </div>
    </motion.div>
  );
}
