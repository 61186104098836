import { Table } from "tc-minibox"
import { icons_config } from "../../../../config/context.config"
import { TableForProps } from "tc-minibox/dist/types/table/index.table"
import { useTranslation } from "react-i18next"
import { hostname } from "../../../../config/server.config"
import { removeDuplicates } from "../../../merchandises/Merchandises"
import {ReactComponent as NoPicSVG} from "../../../../assets/svg/illustrations/no-pictures.svg"


export interface AssociatedRawMatInvDetailProps {
    merchandises : any,
    setMerchandises : React.Dispatch<any>
}

export default function AssociatedRawMatInvDetail(props: AssociatedRawMatInvDetailProps) {
        // * GENERAL VARIABLES 
        const {t} = useTranslation()
        const translate = (key:string) => t(`invoices.detail.${key}`)

        // * VARIABLES
        const tbody_rawmat = props.merchandises.map((dt:any) => {
            return {
                ...dt, 
                pictures : JSON.parse(dt.pictures), 
                unit_rp_amount : `${parseFloat(dt.unit_rp_amount).toFixed(2)} IDR`,
                exchange_rate : `${parseFloat(dt.exchange_rate).toFixed(2)} IDR`,
                cost_price : `${((parseFloat(dt.unit_rp_amount) * parseFloat(dt.quantity)) / parseFloat(dt.exchange_rate)).toFixed(3)}€`,
                total : `${parseFloat(dt.unit_rp_amount) * parseFloat(dt.quantity)} IDR`
            }
        })
        /*const tbody_merchandises = props.merchandises.map((merch:any) => {
            return {
                ...merch, 
                amount : `${merch.unit_rp_amount.toFixed(2)} IDR`,
                pictures : JSON.parse(merch.pictures)
            }
        })*/
        // * COMPONENTS CONFIG
        const table_rawmat_config:TableForProps = {
            height : 80, 
            editing : false, 
            icons : icons_config, 
            config : [
                {
                    info : {
                        text : translate("photo"), 
                        name : "photo", 
                        custom : (tr) => {
                            return (
                                <div className = "photo">
                                {
                                    tr.pictures.length === 0 ? 
                                        <div className = "no-pic">
                                            <NoPicSVG />
                                        </div>
                                    : 
                                        <img src = {`${hostname}/${tr.pictures[0]}`} alt = {`${tr.id}-IMG-${tr.code} `}/>
                                }
                                </div>
                            )
                        }
                    }, 
                    style : {
                        width : "200px",
                    }
                },
                {
                    info : {
                        text : "Code", 
                        name : "code", 
                    }, 
        
                    style : {
                        width : "150px",
                    }
                },
                {
                    info : {
                        text : translate("name"), 
                        name : "name"
                    }, 
                    style : {
                        width : `calc(100% - 670px)`, 
                        tbody : {
                            textAlign : "center"
                        }
                    }
                },  
                {
                    info : {
                        text : t('merchandises.table.quantity'), 
                        name : "quantity", 
                    }, 
                    style : {
                        width : "60px",
                        tbody : {
                            textAlign : "center"            
                        }
                    }
                },
                {
                    info : {
                        text : t('merchandises.table.unit_rp_amount'), 
                        name : "unit_rp_amount", 
                    }, 
                    style : {
                        width : "120px",
                        tbody : {
                            textAlign : "end", 
                            
                        }
                    }
                },
                {
                    info : {
                        text : 'Total', 
                        name : "total", 
                    }, 
                    style : {
                        width : "140px",
                        tbody : {
                            textAlign : "end"
                        }
                    }
                },
            ],
            data : tbody_rawmat,
            setData : props.setMerchandises,
            header : {
                buttons : {
                    include : [/*"add" , "delete"*/], 
                    actions : {
                        /*add: () => setManager(state => {return {...state, add : true}}), 
                        delete : (e:any) => {
                            dispatch(set_popup_warning({
                                status : true, 
                                text : "Êtes-vous sûr de vouloir supprimer ?", 
                                handleYes : () => handleDelete(e)
                            }))
    
                        }, */
    
                    }
                }
            }, 
            //handleDoubleClick : (tr:any) => handleDoubleClick(tr)
        }
    
    return (
        <div className="inv-detail__associated-merch">
            <Table {...table_rawmat_config} />
        </div>
    )
}