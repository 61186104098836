/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { ServerProps } from "../../../../config/server.config"
import SearchSelect from "../../../../tools/components/general/SearchSelect"
import {ReactComponent as WalletBro} from '../../../../assets/svg/illustrations/wallet-bro.svg' ; 
import {ReactComponent as OkAmico} from '../../../../assets/svg/illustrations/ok-amico.svg' ;
import { generateRandomCode } from "../../../transactions/transactions.utils"
import { DatePicker, Icons, Table } from "tc-minibox"
import { icons_config } from "../../../../config/context.config"
import { useDispatch, useSelector } from "react-redux"
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../../../store/global/actions.global"
import MainButton from "../../../../tools/components/general/MainButton"
import { TableForProps } from "tc-minibox/dist/types/table/index.table"
import { InvDetailManager } from "../InvDetail";
import { useTranslation } from "react-i18next";
import BooleanButton from "../../../../tools/components/general/BooleanButton";
import { account_user } from "../../../../store/account/selector.account";
import ContainerLoad from "../../../../tools/components/general/ContainerLoad";



export interface PaymentsInvDetailProps extends ServerProps {
    manager : InvDetailManager, 
    setManager : React.Dispatch<InvDetailManager>,
    transactions : any,
    setTransactions : React.Dispatch<any>,
    invoice : any, 
    setInvoice : React.Dispatch<any>, 
    handleReload : () => void
}

interface PaymentsInvDetailManager{
    load : false
}

const initializeNewTransaction = {
    id_trans : null, 
    external  : false, 
    type : "payment", 
    date : new Date(), 
    idr : 0, 
    eur : 0
}

export default function PaymentsInvDetail(props: PaymentsInvDetailProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const translate = (key:string) => t(`invoices.detail.${key}`)
    const user_account = useSelector(account_user)
    const admin = user_account.infos.permission_type === "admin"

    
    // * STATES
    const [newTransaction, setNewTransaction] = useState<any>({
        ...initializeNewTransaction, 
        code : generateRandomCode("PAY")
    })
    const [selectedTransaction, setSelectedTransaction] = useState<any>({})
    const [manager, setManager] = useState<PaymentsInvDetailManager>({load : false})

    // * VARIABLES
    const amount_paid = props.transactions.map((trans:any) => trans.amount * trans.exchange_rate).reduce((a:number, b:number) => a + b, 0)
    const tbody = props.transactions.map((trans:any) => {
        return {
            ...trans, 
            amount : (trans.amount * trans.exchange_rate).toFixed(2),
            exchange_rate : `${trans.exchange_rate.toFixed(2)}`, 
            withdrawal_code : trans.withdrawal ? trans.withdrawal.code : "", 
            external_text : trans.external ?  t('commons.true') : t('commons.false')
        }
    })
    const exchangeRateForEuro = newTransaction.idr / newTransaction.eur
    

    // * FUNCTIONS
    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:string|RegExp = '') => {
        const value = e.target.value.replaceAll(',', ".")
        
        if (reg instanceof RegExp && !reg.test(value)) {
            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
            return;
        }

        setNewTransaction((state:any) => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }

    const handleSubmit = () => {
        const amount = newTransaction.external ? newTransaction.eur : newTransaction.idr / selectedTransaction.exchange_rate
        const exchange_rate = newTransaction.external ? exchangeRateForEuro : selectedTransaction.exchange_rate
        const data = {
            ...newTransaction, 
            id_inv : props.invoice.id, 
            amount : amount , 
            exchange_rate : exchange_rate,
            url : null
        }
        setManager((state:any) => {return{...state, load : true}})
        dispatch(setLoaderStatus({status : true, message : "Chargement de la facture en cours..."}))
        props.server.post('/transactions/add', data)
        .then((res:any) => {
            props.setTransactions([...props.transactions, res.data.data])
            dispatch(addRequestStatus(res.data))
            setNewTransaction((state:any) => {return {...state, id_trans : null, idr : 0, code : generateRandomCode("PAY") }})
            dispatch(setLoaderStatus({status : false, message : ""}))
            setManager((state:any) => {return{...state, load : false}})
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
            setManager((state:any) => {return{...state, load : false}})
        })
    }

    const handleDelete = (e:any) => {
        const data = e.map((el:any )=> el.id)
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('/transactions/delete-payment', {ids : data, id_inv : props.invoice.id, type : props.invoice.type})
        .then((res:any) => {
            props.setTransactions(props.transactions.filter((el:any) => !data.includes(el.id)))
            dispatch(addRequestStatus(res.data))
            setSelectedTransaction({})
            setNewTransaction((state:any) => {return {...state, id_trans : null, idr : 0, code : generateRandomCode("PAY") }})
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    // * USE EFFECTS 
    useEffect(() => {
        if(newTransaction.id_trans !== null) {
            dispatch(setLoaderStatus({status : true, message : "Chargement de la facture en cours..."}))
            props.server.post('/transactions/get-withdrawal', {id : newTransaction.id_trans})
            .then((res:any) => {
                setSelectedTransaction(res.data.data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [newTransaction.id_trans])




    // * COMPONENT CONFIG
    const table_config:TableForProps = {
        height : 30, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Date", 
                    name : "date",
                    type : "date"
                }, 
                style : {
                    width : `calc(${100/5}% - 20px) `, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Code", 
                    name : "code",
                }, 
                style : {
                    width : `calc(${100/5}% - 20px)`, 
                    tbody : {

                    }
                }
            }, 
            {
                info : {
                    text : "IDR", 
                    name : "amount",
                }, 
                style : {
                    width : `calc(${100/5}% - 20px)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : translate("rate"), 
                    name : "exchange_rate",
                }, 
                style : {
                    width : `calc(${100/5}% - 20px)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            },
            {
                info : {
                    text : translate("withdrawal-code"), 
                    name : "withdrawal_code",
                }, 
                style : {
                    width : `calc(${100/5}% - 20px)`,  
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : translate("external"), 
                    name : "external_text",
                }, 
                style : {
                    width : `100px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody,
        setData : props.setTransactions,
        header : {
            buttons : {
                include : admin ? ["delete"] : [], 
                actions : {
                    /*add: () => setManager(state => {return {...state, add : true}}), */
                    delete : (e:any) => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ? Cette action est irréversible.", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 

                }
            }
        }, 
        //handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }


    return (
        <div className="inv-detail__payments">
            <div id = "transaction">
                {
                    (props.invoice.rp_amount - amount_paid) !== 0 ? 
                        <React.Fragment>
                            <div className = "code">
                                <p>Code</p>
                                <p>{newTransaction.code}</p>
                            </div>

                            {
                                admin ? 
                                    <div id = "external">
                                        <p>{translate('external-operation')}</p>
                                        <BooleanButton
                                            state = {newTransaction.external}
                                            setState = {(val) => setNewTransaction((state:any) => {
                                                return {
                                                    ...state, 
                                                    external : val, 
                                                    idr : 0, 
                                                    eur : 0, 
                                                    id_trans : null, 
                                                }
                                            })}
                                        />
                                    </div>
                                : 
                                    null
                            }

                            <div className="date">
                                <label htmlFor = "date">Date</label>
                                <DatePicker
                                    selected={new Date(newTransaction.date)} 
                                    dateFormat="dd/MM/yyyy"
                                    onChange = {(e:any) => setNewTransaction((state:any) => {return{...state, date : e}})}
                                    locale="fr"       
                                />
                            </div>

                            {
                                !newTransaction.external ?  
                                    <React.Fragment>
                                        <div className="id_trans">
                                            <label htmlFor = "id_trans">{translate("choose-your-withdrawal")}</label>
                                            <SearchSelect
                                                {...{
                                                    text_no_data : translate("no-withdrawal-found"), 
                                                    handleClick : (data) => setNewTransaction((state:any) => {return {...state, id_trans : data.id}}),
                                                    handleDelete : () => setNewTransaction((state:any) => {return {...state, id_trans : null}}),
                                                    no_data_text : translate("no-withdrawal-found"), 
                                                    server : {
                                                        axios : props.server,
                                                        route : "/transactions/search",
                                                    },
                                                    value : newTransaction.id_trans
                                                }}
                                            />
                                        </div>

                                        {
                                            newTransaction.id_trans !== null ? 
                                                <div className="amount">
                                                    <label htmlFor="idr">IDR</label>
                                                    <div className="amount__container">
                                                        <div className="amount__container--available">
                                                            <p>{translate("invoice")}</p>
                                                            <p>{props.invoice.rp_amount.toFixed(2)} IDR</p>
                                                        </div>
                                                        <div className="amount__container--input">
                                                            <label htmlFor = "amount">{translate("amount")}</label>
                                                            <div 
                                                                className={`status ${((selectedTransaction.amount * selectedTransaction.exchange_rate) - newTransaction.idr) < 0 ? "status--error" : "status--success"}`}
                                                            >
                                                                {((selectedTransaction.amount * selectedTransaction.exchange_rate) - newTransaction.idr).toFixed(2)} IDR
                                                            </div>
                                                            <input 
                                                                type="text" 
                                                                name = "idr"
                                                                value = {newTransaction.idr}
                                                                placeholder="1243"
                                                                onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                                                    handleChangeInput(e, /^\d*\.?\d*$/)
                                                                }}
                                                                //onBlur = {() => handleBlur("unit_rp_amount")}
                                                            />
                                                        </div>
                                                        <div className="amount__container--remaining">
                                                            <p>{translate("remaining")}</p>
                                                            <p
                                                                className={`status ${(props.invoice.rp_amount - amount_paid - newTransaction.idr) === 0 ? "status--paid" : (props.invoice.rp_amount - amount_paid - newTransaction.idr) < 0 ? "status--error" : ""}`}
                                                            >
                                                                {
                                                                    (props.invoice.rp_amount - amount_paid - newTransaction.idr) === 0 ? 
                                                                        translate("paid")
                                                                    : 
                                                                    (props.invoice.rp_amount - amount_paid - newTransaction.idr) < 0 ? 
                                                                        translate("error")
                                                                    : 
                                                                    `${(props.invoice.rp_amount - amount_paid - newTransaction.idr).toFixed(2)} IDR`
                                                                } 
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <MainButton
                                                        handleClick={handleSubmit}
                                                    >
                                                        {t("commons.save")}
                                                    </MainButton>


                                                </div>
                                            :
                                             null
                                        }
                                    </React.Fragment>
                                : 
                                    <div className="amount">
                                        <label htmlFor="idr">IDR</label>
                                        <div className="amount__container">
                                            <div className="amount__container--available">
                                                <p>{translate("invoice")}</p>
                                                <p>{props.invoice.rp_amount.toFixed(2)} IDR</p>
                                            </div>
                                            <div className="amount__container--input">
                                            <div className="external-payment">
                                    <label htmlFor = "amount">{translate("amount")}</label>
                                    <div className="currencies_values">
                                        <div>
                                            <input 
                                                type="text" 
                                                name = "eur"
                                                value = {newTransaction.eur}
                                                onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChangeInput(e, /^\d*\.?\d*$/)
                                                }}
                                                //onBlur = {() => handleBlur("unit_rp_amount")}
                                            />
                                        </div>

                                                <React.Fragment>
                                                    <Icons name = "arrow-down-arrow-up" {...icons_config} />
                                                    <div>
                                                        <input 
                                                            type="text" 
                                                            name = "idr"
                                                            value = {newTransaction.idr}
                                                            onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                                                handleChangeInput(e, /^\d*\.?\d*$/)
                                                            }}
                                                            //onBlur = {() => handleBlur("unit_rp_amount")}
                                                        />
                                                    </div>
                                                <div className="exchangeRateForEuro">
                                                        1 EUR = {exchangeRateForEuro.toFixed(2)} IDR
                                                    </div>

                                                </React.Fragment>
                                    </div>
                                    </div>
                                            </div>
                                            <div className="amount__container--remaining">
                                                <p>{translate("remaining")}</p>
                                                <p
                                                    className={`status ${(props.invoice.rp_amount - amount_paid - newTransaction.idr) === 0 ? "status--paid" : (props.invoice.rp_amount - amount_paid - newTransaction.idr) < 0 ? "status--error" : ""}`}
                                                >
                                                    {
                                                        (props.invoice.rp_amount - amount_paid - newTransaction.idr) === 0 ? 
                                                            translate("paid")
                                                        : 
                                                        (props.invoice.rp_amount - amount_paid - newTransaction.idr) < 0 ? 
                                                            translate("error")
                                                        : 
                                                        `${(props.invoice.rp_amount - amount_paid - newTransaction.idr).toFixed(2)} IDR`
                                                    } 
                                                </p>
                                            </div>
                                        </div>

                                        <MainButton
                                            handleClick={handleSubmit}
                                        >
                                            {t("commons.save")}
                                        </MainButton>


                                    </div>

                            }


                        </React.Fragment>
                    : 
                        <div id="transaction--paid">
                            <OkAmico />
                            <p>Facture soldée</p>
                        </div>
                }
                


            </div>
            <div id="history">
                <ContainerLoad load = {manager.load}>
                {
                        props.transactions.length > 0 ? 
                            <React.Fragment>
                                <div className="history--container">
                                    <Table {...table_config} />
                                </div>
                                <div className="history--balance">
                                    
                                </div>
                            </React.Fragment>
                        : 
                            <div className="history--empty">
                                <WalletBro />
                                <p>Aucune transaction enregistrée</p>
                            </div>

                }
                </ContainerLoad>
            </div>
        </div>
    )
}