import {useRef, useCallback} from 'react'; 
import {useDropzone} from 'react-dropzone';
import {ReactComponent as AddIllustration} from '../../../assets/svg/illustrations/upload-rafiki.svg' ; 
import {ReactComponent as DragOn} from '../../../assets/svg/icons/drag_on.svg' ; 
import { Icons } from 'tc-minibox';
import { useTranslation } from 'react-i18next';
import { icons_config } from '../../../config/context.config';

export interface ImportProps {
    multiple? : boolean, 
    mode? : "normal" | "reduced"
    subtitle : string,
    nameClass? : string,
    handleSubmit : (files:any) => void
}

/*const dragNdrop_animation = {
    initial : {y : 100, opacity : 0}, 
    animate : {y : 0, opacity : 1, transition : {type : "linear", delay : 0.6}}, 
    exit : {y : 100, opacity : 0}
}*/

export default function ImportModule(props:ImportProps) {
    /*
        Neccesary props : 
            subtitle = {}
            file = {}
            handleSubmit = {}
    */
    const { t } = useTranslation();

    const mode = props.mode ?? "normal"
    const fileInputRef = useRef(null)
    const onDrop = useCallback((acceptedFiles:File[]) => {
        props.handleSubmit(props.multiple ? acceptedFiles : acceptedFiles[0])
    }, [props])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: false})

//t("invoices.table.supplier")

    return (
        <div 
            className={`import ${props.nameClass ? props.nameClass : ""} ${mode === "reduced" ? "import-reduced" : ""}`}
            {...getRootProps()}
        > 
            {
                mode === "reduced" ?
                    <div className='other' >
                        <Icons name = "upload" {...icons_config} />
                        <div className='description'>
                            <p>{t('basictools.import-module.text-introduction')}</p>
                            <p>{props.subtitle}</p>
                        </div>
                    </div>
                : 
                    <div>
                    <div className='import__intro' >
                                <h1>{t('basictools.import-module.title')}</h1>
                                <p>{props.subtitle}</p>
                            </div>

                        <div 
                            className='import__illustration'
                            
                        >   
                            <AddIllustration />
                            <p>
                                {t('basictools.import-module.text-introduction')}
                            </p>
                        </div>
                    </div>
            }





        {
            isDragActive ?
                <div className='import__dragMode'>
                    <DragOn />    
                </div>
            : null
        }

        <input 
            type = "file"
            ref={fileInputRef} 
            {...getInputProps()}
        />




        </div>


    )
}