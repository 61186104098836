import moment from "moment"
import {motion} from 'framer-motion'
import { TableForProps } from "tc-minibox/dist/types/table/index.table"
import {ReactComponent as WalletBro} from '../../../../assets/svg/illustrations/wallet-bro.svg'
import {ReactComponent as NoData} from '../../../../assets/svg/illustrations/no_data.svg'
import {DatePicker, ObjectUtils, Table} from 'tc-minibox'
import { icons_config } from "../../../../config/context.config"
import { useTranslation } from "react-i18next"
import { ServerProps, hostname } from "../../../../config/server.config"
import { removeDuplicates } from "../../../merchandises/Merchandises"
import {ReactComponent as NoPicSVG} from '../../../../assets/svg/illustrations/no-pictures.svg'
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { account_user } from "../../../../store/account/selector.account"
import SearchSelect from "../../../../tools/components/general/SearchSelect"
import { addRequestStatus, setLoaderStatus } from "../../../../store/global/actions.global"




export interface GeneralitiesInvDetailProps extends ServerProps{
    invoice : any, 
    setInvoice : React.Dispatch<any>, 
    transactions : any,
    setTransactions : React.Dispatch<any>,
    rerender : () => void
}



export default function GeneralitiesInvDetail(props: GeneralitiesInvDetailProps) {
    // * GENERAL VARIABLES 
    const {t} = useTranslation()
    const translate = (key:string) => t(`invoices.detail.${key}`)
    const user = useSelector(account_user)
    const dispatch = useDispatch()

    // * STATES
    const [edit, setEdit] = useState(false)
    const [informations, setInformations] = useState<any>({
        inv_number : props.invoice.inv_number, 
        date : props.invoice.date, 
        id_supplier : props.invoice["supplier.id"], 
        rp_amount : props.invoice.rp_amount
    })

    //COMPARE INFORMATIONS AND PROPS.INVOICE TO GET VALUE DIFFERENCE
    const getDifferenceInformations = () => {
        const oldInv = {
            inv_number : props.invoice.inv_number, 
            date : props.invoice.date, 
            id_supplier : props.invoice["supplier.id"], 
            rp_amount : props.invoice.rp_amount
        }
        const newInv = {
            ...informations, 
            rp_amount : parseInt(informations.rp_amount)
        }

        return ObjectUtils.getObjectDifference(oldInv, newInv)
    }

    // * VARIABLES
    const tbody_payments = props.transactions.map((trans:any) => {
        return {
            ...trans, 
            amount : (trans.amount * trans.exchange_rate).toFixed(2),
            withdrawal_code : trans.withdrawal ? trans.withdrawal.code : "", 
            external_text : trans.external ?  t('commons.true') : t('commons.false')
        }
    })

    var tbody_merchandises = []
    
    const pre_tbody = props.invoice.merchandises.map((dt:any) => {
        if(props.invoice.type === "finished_products") {
            const decomposed_code = dt.code.split('-')
            return {
                ...dt, 
                total : `${parseFloat(dt.unit_rp_amount) * parseFloat(dt.quantity)} IDR`,
                size_text : `${dt.size.w} x ${dt.size.d} x ${dt.size.h}`, 
                qr_supplier : decomposed_code[0], 
                qr_code : decomposed_code[1], 
                qr_batch : decomposed_code[2], 
                pictures : JSON.parse(dt.pictures)
            }
        } else {
            const decomposed_code = dt.code.split('-')
            return {
                ...dt, 
                pictures : JSON.parse(dt.pictures)
            }
        }

    })

    if(props.invoice.type === "finished_products") {
        const getUnique = pre_tbody
        .filter((el:any) => pre_tbody.filter((pt:any) => `${pt.qr_supplier}-${pt.qr_code}` === `${el.qr_supplier}-${el.qr_code}`).length === 1)
        .map((el:any) => {
            return {
                ...el, 
                total : `${parseFloat(el.unit_rp_amount) * parseFloat(el.quantity)} IDR`,
                code : `${el.qr_supplier}-${el.qr_code}`,
                cost_price : parseFloat(el.unit_rp_amount) * parseFloat(el.quantity) * parseFloat(el.exchange_rate),
                
            }
        })
        const batched_lines = pre_tbody.filter((el:any) => pre_tbody.filter((pt:any) => `${pt.qr_supplier}-${pt.qr_code}` === `${el.qr_supplier}-${el.qr_code}`).length > 1)
        const getBatch = removeDuplicates(batched_lines).map((dt:any) => {
        const quantity = batched_lines.filter((bl:any) => `${bl.qr_supplier}-${bl.qr_code}` === `${dt.qr_supplier}-${dt.qr_code}`).length
        return {
        ...dt, 
        code : `${dt.qr_supplier}-${dt.qr_code}`,
        quantity : quantity, 
        batched : true, 
        cost_price : `${(parseFloat(dt.unit_rp_amount) * quantity * parseFloat(dt.exchange_rate)).toFixed(2)}€`,
        total : `${parseFloat(dt.unit_rp_amount) * parseFloat(quantity)} IDR`,
        }
        })

        tbody_merchandises = [
            ...getUnique, 
            ...getBatch
            ].map(el => {
            return {
            ...el, 
            unit_rp_amount : `${parseFloat(el.unit_rp_amount).toFixed(2)} IDR`,
            exchange_rate : `${parseFloat(el.exchange_rate).toFixed(2)} IDR`,
            cost_price : `${((parseFloat(el.unit_rp_amount) * parseFloat(el.quantity)) / parseFloat(el.exchange_rate)).toFixed(3)}€`,
            }
        })
    } else {
        tbody_merchandises = pre_tbody.map((el:any) => {
            return {
                ...el, 
                unit_rp_amount : `${parseFloat(el.unit_rp_amount).toFixed(2)} IDR`,
                exchange_rate : `${parseFloat(el.exchange_rate).toFixed(2)} IDR`,
                cost_price : `${((parseFloat(el.unit_rp_amount) * parseFloat(el.quantity)) / parseFloat(el.exchange_rate)).toFixed(3)}€`,
                total : `${parseFloat(el.unit_rp_amount) * parseFloat(el.quantity)} IDR`
            }
        })
    }



    // * COMPONENTS CONFIG
    const table_merchandises_config:TableForProps = {
        height : 80, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : translate("photo"), 
                    name : "photo", 
                    custom : (tr) => {

                        return (
                            <div className = "photo">
                            {
                                tr.pictures.length === 0 ? 
                                    <div className = "no-pic">
                                        <NoPicSVG />
                                    </div>
                                : 
                                    <img src = {`${hostname}/${tr.pictures[0]}`} alt = {`${tr.id}-IMG-${tr.code} `}/>
                            }
                            </div>
                        )
                    }
                }, 
                style : {
                    width : "200px",
                }
            },
            {
                info : {
                    text : "Code", 
                    name : "code", 
                }, 
    
                style : {
                    width : "150px",
                }
            },
            {
                info : {
                    text : translate("name"), 
                    name : "name"
                }, 
                style : {
                    width : `calc(100% - 670px)`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
            {
                info : {
                    text : t('merchandises.table.quantity'), 
                    name : "quantity", 
                }, 
                style : {
                    width : "60px",
                    tbody : {
                        textAlign : "center"            
                    }
                }
            },
            {
                info : {
                    text : t('merchandises.table.unit_rp_amount'), 
                    name : "unit_rp_amount", 
                }, 
                style : {
                    width : "120px",
                    tbody : {
                        textAlign : "end", 
                        
                    }
                }
            },
            {
                info : {
                    text : 'Total', 
                    name : "total", 
                }, 
                style : {
                    width : "140px",
                    tbody : {
                        textAlign : "end"
                    }
                }
            },
        ], 
        data : tbody_merchandises,
        setData : props.setInvoice,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    /*add: () => setManager(state => {return {...state, add : true}}), 
                    delete : (e:any) => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, */

                }
            }
        }, 
        //handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }

    const table_payments_config:TableForProps = {
        height : 30, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Date", 
                    name : "date",
                    type : "date"
                }, 
                style : {
                    width : `calc(${100/4}% - 25px) `, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Code", 
                    name : "code",
                }, 
                style : {
                    width : `calc(${100/4}% - 25px)`, 
                    tbody : {

                    }
                }
            }, 
            {
                info : {
                    text : translate("amount"), 
                    name : "amount",
                }, 
                style : {
                    width : `calc(${100/4}% - 25px)`, 
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : translate("withdrawal-code"), 
                    name : "withdrawal_code",
                }, 
                style : {
                    width : `calc(${100/4}% - 25px)`,  
                    tbody : {
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : translate("external"), 
                    name : "external_text",
                }, 
                style : {
                    width : `100px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody_payments,
        setData : props.setTransactions,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    /*add: () => setManager(state => {return {...state, add : true}}), 
                    delete : (e:any) => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, */

                }
            }
        }, 
        //handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }

    const handleUpdate = () => {

        if(informations.id_supplier === null) {
            return dispatch(addRequestStatus({
                status : false, 
                message: t("invoices.statusRequest.error-supplier")
            }))
        }

        if(informations.inv_number === "") {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.statusRequest.error-inv-number")
            }))
        }
        if(informations.rp_amount === "") {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.statusRequest.error-rp-amount")
            }))
        }

        dispatch(setLoaderStatus({status : true}))

        return props.server.post('/invoices/update', {token : props.invoice.token, content : getDifferenceInformations()})
        .then(res => {
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : t(res.data.message)
            }))
            props.rerender()
            //dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    return (
        <div className="inv-detail__generalities">
            <div id = "informations">
                <div className="edit-control">
                    {
                        edit ? 
                            <React.Fragment>
                                {
                                    Object.keys(getDifferenceInformations()).length > 0 ? 
                                        <div className="edit-control--true" onClick = {handleUpdate}>
                                            Save
                                        </div>
                                    : 
                                        null
                                }
                                <div 
                                    onClick = {() => {
                                        setEdit(false)
                                        setInformations({
                                            inv_number : props.invoice.inv_number, 
                                            date : props.invoice.date, 
                                            id_supplier : props.invoice["supplier.id"], 
                                            rp_amount : props.invoice.rp_amount
                                        })
                                    }}
                                    className="edit-control--false"
                                >
                                    Cancel
                                </div>
                            </React.Fragment>
                        : 
                            <motion.div 
                                onClick = {() => setEdit(true)}
                                className="edit-control--button"
                            >
                                Update
                            </motion.div>
                    }

                </div>
                <div className="informations--header">
                </div>
                <div className="informations--container">
                    <div id = "inv-number">
                        <p>{translate("inv-number")}</p>
                        {
                            edit ? 
                                <input 
                                    type="text" 
                                    value = {informations.inv_number} 
                                    onChange = {(e:any) => setInformations({...informations, inv_number : e.target.value})}
                                />
                            : 
                                <p>{props.invoice.inv_number}</p>
                        }
                    </div>
                    <div id = "date">
                        <p>Date</p>
                        {
                            edit ? 
                                <DatePicker
                                    selected={new Date(props.invoice.date)}
                                    dateFormat="dd/MM/yyyy"
                                    onChange = {(e:any) => setInformations({...informations, date : e})}
                                    locale="fr"       
                                />
                            : 
                                <p>{moment(props.invoice.date).format('DD/MM/YYYY')}</p>
                        }
                    </div>
                    <div id = "id_supplier">
                        <p>{translate("supplier")}</p>
                        {
                            edit ? 
                                <SearchSelect
                                {...{
                                    text_no_data : "Aucune affaire trouvée", 
                                    handleClick : (data) => setInformations((state:any) => {return {...state, id_supplier : data.id}}),
                                    handleDelete : () => setInformations((state:any) => {return {...state, id_supplier : null}}),
                                    no_data_text : t("invoices.status.no-supplier-found"), 
                                    server : {
                                        axios : props.server,
                                        route : "/suppliers/search",
                                    },
                                    value : informations.id_supplier
                                    }}
                                />
                            : 
                                <p>{props.invoice["supplier.name"]}</p>
                        }
                    </div>
                    <div id = "amount">
                        <p>{translate("amount")}</p>
                        {
                            edit && user.infos.permission_type === "admin" ? 
                                <input 
                                    type="text" 
                                    value = {informations.rp_amount} 
                                    onChange = {(e:any) => {
                                        const value = e.target.value.replaceAll(',', ".")

                                        // Si reg est une expression régulière, on la teste
                                        if (!/^\d*\.?\d*$/gm.test(value)) {
                                            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
                                            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
                                            return;
                                        }
                                
                                        setInformations({...informations, rp_amount : value})}
                                    }
                                />
                            : 
                                <p>{`${props.invoice.rp_amount} IDR`}</p>
                        }
                    </div>
                </div>

            </div>

            <div id = "payments">
                <div className="payments--header">
                    <h1>{translate("payments")}</h1>
                </div>
                <div className="payments--container">
                    {
                        tbody_payments.length !== 0 ?
                            <Table {...table_payments_config} />
                        : 
                            <div className="payments--no-data">
                                <WalletBro />
                                <p>{translate("no-payments")}</p>
                            </div>
                    }

                </div>
            </div>

            <div id = "merchandises">
                <div className="merchandises--header">
                    <h1>{props.invoice.type === "raw_materials" ? translate("raw-materials") : translate("finished-products")}</h1>
                </div>
                <div className="merchandises--container">
                    {
                        tbody_merchandises.length !== 0 ?
                            <Table {...table_merchandises_config} />
                        : 
                            <div className="merchandises--no-data">
                                <NoData />
                                <p>{translate("no-merchandises")}</p>
                            </div>
                    }

                </div>

            </div>

        </div>
    )
}