import {motion} from 'framer-motion' ; 
import { FormSupplierManager } from '../Manager';
import { useTranslation } from 'react-i18next';

export interface PostalAddressProps {
    form : FormSupplierManager, 
    setForm : React.Dispatch<React.SetStateAction<FormSupplierManager>>, 
    handleChangeInput : (e : React.ChangeEvent<HTMLInputElement>, regex? : RegExp) => void
        
}



export default function PostalAddress(props:PostalAddressProps) {
    const {t} = useTranslation()
    const translate = (key:string) => t(`suppliers.manager.labels.${key}`)

    return (
        <motion.div className='address'>
            <div id='postal_address'>
                <label htmlFor='address'>{translate("address")}</label>
                {
                    props.form.address.map((el, index) => (
                        <div key = {`ADDRESS${index}`}>
                            <input
                                type = "text"
                                name = "address"
                                placeholder={['Ex : 1 rue de la paix', 'Ex : BP 123'][index]}
                                value = {props.form.address[index]}
                                onChange = {(e) => {props.setForm(state => {return({...state, address : state.address.map((el, i) => i === index ? e.target.value : el)})})}}
                            />
                        </div>
                    ))
                }
            </div>
            {
                /*
                <div id='zip_code'>
                    <label htmlFor='zip_code'>{translate("zip_code")}</label>
                    <input
                        type = "text"
                        name = "zip_code"
                        placeholder='Ex : 75000'
                        value = {props.form.zip_code}
                        onChange = {(e) => {props.handleChangeInput(e, /[^0-9]/g)}}
                    />
                </div>
                
                */
            }

            <div id='city'>
                <label htmlFor='city'>{translate("city")}</label>
                <input
                    type = "text"
                    name = "city"
                    placeholder='Ex : Paris'
                    value = {props.form.city}
                    onChange = {props.handleChangeInput}
                />
            </div>
        </motion.div>
    )
}