

type FilterType = {
    name: 'used' | 'available';
    label: string;
};

export const raw_mat_filters:FilterType[] =  [
    {name: 'available', label: 'raw-materials.table.available'},
    {name: 'used', label: 'raw-materials.table.used'},
]


export const identification_thead:any = [
    {
        info : {
            text : 'merchandises.table.supplier', 
            name : "supplier.name", 
        }, 
        style : {
            width : "150px",
        }
    }, 
    {
        info : {
            text : 'merchandises.table.invoice', 
            name : "invoice.inv_number", 
        }, 
        style : {
            width : "150px",
        }
    },
]

export const monetary_thead:any = [
    {
        info : {
            text : 'merchandises.table.used', 
            name : "quantity_used", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "center"            
            }
        }
    },
    {
        info : {
            text : 'merchandises.table.quantity', 
            name : "quantity", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "center"            
            }
        }
    },
    {
        info : {
            text : 'merchandises.table.unit_rp_amount', 
            name : "unit_rp_amount", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "end", 
                
            }
        }
    },
    {
        info : {
            text : 'Total', 
            name : "total", 
        }, 
        style : {
            width : "140px",
            tbody : {
                textAlign : "center"
            }
        }
    },
    {
        info : {
            text : 'merchandises.table.exchange_rate', 
            name : "exchange_rate", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "center"
            }
        }
    },
    {
        info : {
            text : 'merchandises.table.cost_price', 
            name : "cost_price", 
        }, 
        style : {
            width : "120px",
            tbody : {
                textAlign : "end"
            }
        }
    },
]

export const theads_raw_materials:any = [
    {
        id : 'THEADS-MERCH-01', 
        title : "merchandises.views.monetary", 
        svg : "money-bill", 
        width : "620px", 
        content : monetary_thead
    }, 
    {
        id : 'THEADS-MERCH-02', 
        title : "merchandises.views.identification", 
        width : "300px", 
        svg : "home", 
        content : identification_thead
    }, 

]