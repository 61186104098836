import { useSelector } from 'react-redux';
import { account_user } from '../../store/account/selector.account';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { ServerProps } from '../../config/server.config';
import BaseApp from '../../tools/components/general/BaseApp';
import { useTranslation } from 'react-i18next';

export interface HomepageProps extends ServerProps {

}


export default function Homepage(props:HomepageProps) {
    const user_account = useSelector(account_user)
    const {t} = useTranslation()

    return (
        <BaseApp>
            <BaseContainer nameClass = "homepage">
                <div className='welcome'>
                    <h1>{`${t("homepage.greeting")}, ${user_account.infos.firstname} ${user_account.infos.lastname}`}</h1>
                    
                    
                    <h2>{t("homepage.welcomeBack")} !</h2>

{                    /*<div className='actions'>
                        <p>{t("homepage.actionPrompt")}</p>

                    </div>*/}

                </div>

            </BaseContainer>
        </BaseApp>

    )
}

/*                    
    <motion.div>
        <Icons name = "magnifying-glass" {...icons_config} />
        <input type = "text" placeholder='Rechercher une affaire' />
    </motion.div>
*/