/* eslint-disable no-useless-escape */
import { Axios } from "axios";
import SearchSelect from "../../../../tools/components/general/SearchSelect";
import { MerchandiseModuleCategorizer } from "../../merchandises.types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../../store/global/actions.global";


export interface CharacteristicsMerchProps extends MerchandiseModuleCategorizer {
    server : Axios
}

export default function CharacteristicsMerch(props:CharacteristicsMerchProps) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    // FUNCTIONS
    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:string|RegExp = '') => {
        const value = e.target.value

        // Si reg est une expression régulière, on la teste
        if (reg instanceof RegExp && !reg.test(value)) {
            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
            return;
        }


        if(["w", "d", "h"].includes(e.target.name)) {
            return props.setState((state:any) => {
                return {
                    ...state, 
                    size : {
                        ...state.size, 
                        [e.target.name] : value.replace(',', '.')
                    }
                }
            })
        }


        props.setState(state => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }

    const handleAddFamily = (e:any) => {
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('/family/add', {name : e})
        .then((res:any) => {
            dispatch(setLoaderStatus({status : false, message : ""}))
            props.setState(state => {
                return {
                    ...state, 
                    id_family : res.data.data.id
                }
            })
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }

    /*const handleAddSubFamily = (e:any) => {
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('/subfamily/add', {name : e, id_family : props.state.id_family})
        .then((res:any) => {
            props.setState(state => {
                return {
                    ...state, 
                    id_subfamily : res.data.data.id
                }
            })
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }*/

    return (
        <div className = "characteristics-merch">
            <div className="id_family">
                <label htmlFor = "id_family">{t('merchandises.table.family')}</label>
                <SearchSelect
                    {...{
                        adding : true, 
                        handleAdd : (e) => handleAddFamily(e), 
                        handleClick : (data) => props.setState((state:any) => {return {...state, id_family : data.id}}),
                        handleDelete : () => props.setState((state:any) => {return {...state, id_family : null, id_subfamily : null}}),
                        no_data_text : "Aucune famille trouvée", 
                        server : {
                            axios : props.server,
                            route : "/family/search",
                        },
                        value : props.state.id_family
                    }}
                />

            </div>
            {
                /*
                props.state.id_family !== null ? 
                    <div className="id_subfamily">
                        <label htmlFor = "id_subfamily">{t('merchandises.table.subfamily')}</label>
                        <SearchSelect
                            {...{
                                adding : true, 
                                handleAdd : (e) => handleAddSubFamily(e), 
                                handleClick : (data) => props.setState((state:any) => {return {...state, id_subfamily : data.id}}),
                                handleDelete : () => props.setState((state:any) => {return {...state, id_subfamily : null}}),
                                no_data_text : "Aucune sous famille trouvée", 
                                server : {
                                    axios : props.server,
                                    route : "/subfamily/search",
                                    body : {id_family : props.state.id_family}
                                },
                                value : props.state.id_subfamily
                            }}
                        />
                    </div>
                : 
                    null
                */
            }
            <div>
                <label htmlFor = "material">{t('merchandises.table.material')}</label>
                <input 
                    type = "text" 
                    name = "material"
                    id = "material" 
                    value = {props.state.material} 
                    onChange = {handleChangeInput}
                />
            </div>
            <div>
                <label htmlFor = "color">{t('merchandises.manager.color')}</label>
                <input 
                    name = "color"
                    type = "text" 
                    id = "color" 
                    value = {props.state.color} 
                    onChange = {handleChangeInput}
                />
            </div>

            <div className = "size">
                <label htmlFor = "size">{t('merchandises.manager.dimensions')}</label>
                <div className="size--container">
                    <div>
                        <label htmlFor = "size--w">{t('merchandises.manager.width')}</label>
                        <input 
                            type = "text" 
                            className = "size--w" 
                            value = {props.state.size.w} 
                            name = "w"
                            onChange = {(e) => handleChangeInput(e, /^(?:\d+(?:[\.,]\d*)?|[\.,]\d+|)$/g)}
                        />
                    </div>
                    <div>
                        <label htmlFor = "size--d">{t('merchandises.manager.depth')}</label>
                        <input 
                            type = "text" 
                            className = "size--d" 
                            value = {props.state.size.d} 
                            name = "d"
                            onChange = {(e) => handleChangeInput(e, /^(?:\d+(?:[\.,]\d*)?|[\.,]\d+|)$/g)}
                        />
                    </div>
                    <div>
                        <label htmlFor = "size--h">{t('merchandises.manager.height')}</label>
                        <input 
                            type = "text" 
                            className = "size--h" 
                            value = {props.state.size.h} 
                            name = "h"
                            onChange = {(e) => handleChangeInput(e, /^(?:\d+(?:[\.,]\d*)?|[\.,]\d+|)$/g)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}