/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence } from "framer-motion";
import { ServerProps, hostname } from "../../config/server.config";
import BaseApp from "../../tools/components/general/BaseApp";
import { DataType, TableForProps } from "tc-minibox/dist/types/table/index.table";
import React, { useEffect, useState } from "react";
import BaseContainer from "../../tools/components/general/BaseContainer";
import { Icons, Table } from "tc-minibox";
import { icons_config, stepsText_position } from "../../config/context.config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../store/global/actions.global";
import { raw_mat_filters, theads_raw_materials} from "./raw_materials.utils";
import StepsText from "../../tools/components/general/StepsText";
import RawMaterialsMan, { RawMaterialsManProps } from "./manager/RawMaterialsMan";
import { MerchandiseModel } from "../../database/merchandise-model";
import { account_user } from "../../store/account/selector.account";
import{ReactComponent as NoPicSVG} from '../../assets/svg/illustrations/no-pictures.svg'


export interface RawMaterialsProps extends ServerProps {

}

export interface RawMaterialsManager {
    initialize : boolean, 
    mode : "list" | "load", 
    add : boolean,
    thead_mode : any, 
    update : MerchandiseModel | null, 
    filter : "all" | "used" | "available",
}


export function removeDuplicates(data: Array<any>): Array<any> {
    
    const uniqueItems: Array<any> = [];
    const seen: Set<string> = new Set();
  
    data.forEach((item: any) => {
      const key: string = `${item.qr_supplier}-${item.qr_code}`;
      if (!seen.has(key)) {
        uniqueItems.push(item);
        seen.add(key);
      }
    });
  
    return uniqueItems;
}


export default function RawMaterials(props: RawMaterialsProps) {
    // * GENERAL VARIABLES
    const {t} = useTranslation()
    const translate_table = (text:string) => t(`raw-materials.table.${text}`)
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)
    const admin = user_account.infos.permission_type === "admin"

    // * STATES
    const [rawMaterials, setRawMaterials] = useState<DataType>([])

    const [manager, setManager] = useState<RawMaterialsManager>({
        add : false, 
        initialize : false, 
        mode : "load", 
        thead_mode : theads_raw_materials[0].id, 
        update : null, 
        filter : "available",
    })

    // * VARIABLES 

    const filter_config = [
        {
            name : "all", 
            label : translate_table("all"),
        }, 
        ...raw_mat_filters
    ]

    const pre_tbody = rawMaterials.map((dt:any) => {
        const decomposed_code = dt.code.split('-')

        return {
            ...dt, 
            qr_supplier : decomposed_code[0], 
            qr_code : decomposed_code[2], 
        }
    })
    const tbody = pre_tbody.map((dt:any) => {
        const verification_value = [parseFloat(dt.unit_rp_amount), parseFloat(dt.exchange_rate), parseFloat(dt.quantity)].includes(0)
        return {
            ...dt, 
            unit_rp_amount : `${parseFloat(dt.unit_rp_amount).toFixed(2)} IDR`,
            exchange_rate : `${parseFloat(dt.exchange_rate).toFixed(2)} IDR`,
            total : `${parseFloat(dt.unit_rp_amount) * parseFloat(dt.quantity)} IDR`,
            quantity_available : parseFloat(dt.quantity) - parseFloat(dt.quantity_used), 
            cost_price : verification_value ? "N/A" :`${((parseFloat(dt.unit_rp_amount) * parseFloat(dt.quantity)) / parseFloat(dt.exchange_rate)).toFixed(3)}€`,
        }
    })
    // * FUNCTIONS
    const handleDelete = (tr:any) => {
        dispatch(setLoaderStatus({status : true, message : ""}))
        const data = tr.map((el:any) => {
            if(el.batched) {
                const retrieve_lines = tbody.filter(tb => `${tb.qr_supplier}-${tb.qr_code}` === `${el.qr_supplier}-${el.qr_code}`)
                
                return retrieve_lines
            }
            return [tbody.find(tb => `${tb.qr_supplier}-${tb.qr_code}` === `${el.qr_supplier}-${el.qr_code}`)]
        }).flat(1)

        props.server.post('/raw_materials/delete', {ids : data.map((t:any) => t.id)})
        .then((res:any) => {
            setManager(state => { return {...state, initialize : false}})
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    //console.log(pre_tbody.filter(pt => `${pt.qr_supplier}-${pt.qr_code}` === `HTP-Q2TWH2AQIJ`).length)
    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : ""}))
            props.server.get('/raw_materials')
            .then((res:any) => {
                setManager(state => { return {...state, initialize : true}})
                console.log(res.data.data)
                const received_data = res.data.data.map((dt:any) => {
                    return {
                        ...dt, 
                        pictures : JSON.parse(dt.pictures.replace(/^"|"$/g, '')), 
                    }
                })

                setRawMaterials(received_data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    // * COMPONENT CONFIG
    const merchandises_man_config:RawMaterialsManProps = {
        rerender : () => setManager(state => {return{...state, initialize : false, add : false}}), 
        server : props.server,
        handleClose : () => setManager(state => {return{...state, add : false, update : null}}), 
        update : manager.update,
    }

    const current_thead = theads_raw_materials
                            .find((tm:any) => tm.id === manager.thead_mode) ? theads_raw_materials
                            .find((tm:any) => tm.id === manager.thead_mode)?.content : [] ; 

    const table_config:TableForProps= {
        height : 70, 
        editing : false, 
        icons : icons_config, 
        config : [
            // *
            {
                info : {
                    text : "Photo", 
                    name : "photo", 
                    custom : (tr) => {
                        return (
                            <div className = "photo">
                                {
                                    tr.pictures.length === 0 ? 
                                        <div className = "no-pic">
                                            <NoPicSVG />
                                        </div>
                                    : 
                                        <img src = {`${hostname}/${tr.pictures[0]}`} alt = {`${tr.id}-IMG-${tr.code} `}/>
                                }
                            </div>
                        )
                    }
                }, 
                style : {
                    width : "200px",
                }
            },
            {
                info : {
                    text : t("merchandises.table.code"), 
                    name : "code", 
                    custom : (tr, td) => {
                        //console.log(tr)
                        return (
                            <div className="table__code">
                                <p>{td}</p>
                                {
                                    /*
                                                                    {
                                    tr.batched ? 
                                        <Icons name="circle-caret-right" color="#383838" mode="duotone" />
                                    :
                                        null
                                }
                                                                    <div className="table__code--view">
                                    <div className="table__code--view--modules">
                                        <div>
                                            <h1>{tr.code}</h1>
                                            <div className="qr-view">
                                                <h2>Code barre</h2>
                                                <img src={`${hostname}/${tr.qrcode_path}`} alt="qr_code-view" />
                                            </div>
                                            <div className="pictures">
                                                <h2>Images</h2>
                                                <div>
                                                {
                                                    tr.pictures.map((pic:any) => {
                                                        return (
                                                            <img src={`${hostname}/${pic}`} alt={`IMG${tr.qr_code}-${tr.qr_batch}`} key = {`IMG${tr.qr_code}-${tr.qr_batch}`} />
                                                        )
                                                    })
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                    */
                                }

                            </div>
                        )
                    }
                }, 
    
                style : {
                    width : "200px" ,
                }
            }, 
            {
                info : {
                    text : t("merchandises.table.name"), 
                    name : "name", 
                }, 
                style : {
                    width : `calc(100% - 200px - 200px - ${theads_raw_materials.find((tm:any) => tm.id === manager.thead_mode) ? theads_raw_materials.find((tm:any) => tm.id === manager.thead_mode)?.width : "800px"})`, 
                    tbody : {
                        //textAlign : "center"
                    }
                }
            }, 
            ...current_thead.map((th:any) => {
                return {
                    ...th, 
                    info : {
                        ...th.info, 
                        text : t(th.info.text)
                    },
                    }
            })
  

        ], 

        data : tbody,
        setData : setRawMaterials,
        header : {
            buttons : {
                include : admin ? ["add", "delete"] : ["add"], 
                actions : {
                    add : () => setManager(state => {return{...state, add : true}}), 
                    delete : (data:any) => {
                        const check_data = data.filter((dt:any) => dt.quantity_used !== 0)

                        if(check_data.length > 0) {
                            return dispatch(addRequestStatus({
                                status : false, 
                                message : t(`raw-materials.status.cant-delete-raw-materials-used`)
                            }))
                        }
                        if(data.length === 0) {
                            return dispatch(addRequestStatus({
                                status : false, 
                                message : t(`raw-materials.status.no-raw-materials-selected`)
                            }))
                            
                        }
                        return dispatch(set_popup_warning({
                            status : true, 
                            text : t(data.length === 1 ? `merchandises.status.delete-merchandise` : `merchandises.status.delete-merchandises`), 
                            handleYes : () => handleDelete(data)
                        }))
                    }
                    
                    

                }
            }, 
            custom : (data:any) => {
                const current_thead = theads_raw_materials.find((tm:any) => tm.id === manager.thead_mode)

                return (
                    <React.Fragment>
                        <div 
                            onClick = {() => setManager(state => {return{...state, initialize : false}})}
                            className="refresh-button"
                        >
                            <Icons name="arrows-repeat" color="rgb(231, 144, 24)"/>
                        </div>
                        <div className = "merchandises_header">


                            <div 
                                style = {{width : `${130 * filter_config.length}px`}}
                                className='filter'
                            >
                                <div 
                                    style = {{
                                        width : `${100 / filter_config.length}%`, 
                                        left : `calc(${(100 / filter_config.length) * filter_config.findIndex(f => f.name === manager.filter) + 1}% - ${filter_config.findIndex(f => f.name === manager.filter) === 0 ? "2.5px" : "6.5px"})`,
                                    }}
                                    className='selector'></div>
                                {
                                    filter_config.map((filter, index:number) => (
                                        
                                        <div 
                                            style = {{width : `${100 / filter_config.length}%`}}
                                            key={`${filter.name}-0${index}`} 
                                            onClick = {() => setManager((state:any) => {return {...state, filter : filter.name}})}
                                            className={`filter__item filter__item--${filter.name} ${manager.filter === filter.name ? "filter__item--active" : ""}`}>
                                            <p>{`${t(filter.label)}`}</p>
                                            <p>
                                                {
                                                    filter.name === "all" ? 
                                                        `${rawMaterials.length}`
                                                    : 
                                                        filter.name === "used" ? 
                                                            `${rawMaterials.filter((rm:any) => (rm.quantity - rm.quantity_used) === 0).length}`
                                                        : 
                                                            `${rawMaterials.filter((rm:any) => (rm.quantity - rm.quantity_used) !== 0).length}`
                                                }
                                            </p>
                                    </div>
                                    ))
                                }
                    
                
                            </div>
                    <div className = "thead_mode">
                                <div className = "thead_mode--current">
                                    <Icons name = {current_thead.svg} color="white" mode="duotone" />
                                    <p>{t(current_thead.title)}</p>
                                </div>

                                <div className = "thead_mode--buttons">
                                    {
                                        theads_raw_materials.filter((tm:any) => tm.id !== manager.thead_mode).map((tm:any) => {
                                            return (
                                                <div 
                                                    key = {tm.id}
                                                    className = {tm.id === manager.thead_mode ? "active" : ""}
                                                    onClick = {() => setManager(state => {return{...state, thead_mode : tm.id}})}
                                                >
                                                    <Icons name = {tm.svg} color="#383838" mode="duotone" />
                                                    <p>{t(tm.title)}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }, 
        filter_custom : (data:any) => {
            if (manager.filter === "used") {
                return data.filter((dt:any) => {
                    const rm = tbody[dt[0]]
                    return (parseInt(rm.quantity) - parseInt(rm.quantity_used)) === 0
                
                })
            }
            if (manager.filter === "available") {
                return data.filter((dt:any) => {
                    const rm = tbody[dt[0]]
                    return (parseInt(rm.quantity) - parseInt(rm.quantity_used)) !== 0
                
                })
            }
            return data
        }, 
        handleDoubleClick : (tr:any) => {
            const selected_data = pre_tbody.filter(pt => `${pt.qr_supplier}-${pt.qr_code}` === `${tr.qr_supplier}-${tr.qr_code}`)
            setManager((state:any) => {return{...state, update : {
                ...tr, 
                ...selected_data[0], 
                merchandises : selected_data, 
                quantity : tr.quantity, 
            }}})
        }
    }



    return (
        <BaseApp>
            <AnimatePresence>
                {
                    manager.add || manager.update !== null ? 
                        <RawMaterialsMan {...merchandises_man_config} />
                    : 
                        null
                }
            </AnimatePresence>
            <BaseContainer nameClass = "merchandises raw-materials">
                <StepsText
                    title = {t("raw-materials.title")}
                    position = {stepsText_position}
                />
                <Table {...table_config} />
            </BaseContainer>
        </BaseApp>
    )
}