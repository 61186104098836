import { ReactNode} from 'react';
import { motion } from 'framer-motion';
import { Icons } from 'tc-minibox';

type BasePopupProps = {
  nameClass?: string;
  handleClose: () => void;
  children: ReactNode;
};

export default function BasePopup(props: BasePopupProps) {

  
  return (
    <motion.div
      exit={{ opacity: 0, transition: { type: 'linear', duration: 0.1, delay: 0.1 } }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { type: 'linear', duration: 0.1 } }}
      className={`basepopup ${props.nameClass ? props.nameClass : ''}`}
    >
      <div className='basepopup__background' onClick={() => props.handleClose()}></div>
      <motion.div
        exit={{ opacity: 0, y: 100, transition: { type: 'linear', duration: 0.1, delay: 0.1 } }}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0, transition: { type: 'linear', duration: 0.1 } }}
        className='basepopup__container'
      >
        <div className='header'>
          <motion.button whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }} onClick={props.handleClose}>
            <Icons name="xmark" mode="duotone" color="white" />
          </motion.button>
        </div>
        <div className='content'>{props.children as JSX.Element}</div>
      </motion.div>
    </motion.div>
  );
}
