export type PaymentType = {
    id: string;
    name: string;
    text: any;
  };
  
export const type_payments:PaymentType[] = [
    {
        id : "TYPE-PAYMENTS-01", 
        name : "check", 
        text : {
            fr : "Chèque", 
            en : "Check"
        }
    }, 
    {
        id : "TYPE-PAYMENTS-02", 
        name : "bank_card", 
        text : {
            fr : "Carte bancaire", 
            en : "Bank card"
        }
    }, 
    {
        id : "TYPE-PAYMENTS-03", 
        name : "bank_transfer", 
        text : {
            fr : "Virement bancaire", 
            en : "Bank transfer"
        }
    }, 
    {
        id : "TYPE-PAYMENTS-04", 
        name : "cash", 
        text :  {
            fr : "Espèces", 
            en : "Cash"
        }
    }, 
]

export type ContactType = {
    id: string;
    name: string;
    placeholder?: string;
    error?: string;
    text: any;
    regex: RegExp;
};

export const contacts_types:ContactType[] = [
    {
        id : "CONTACTS-TYPE-01",
        name : "phone_number",
        text : {
            fr : "Téléphone",
            en : "Phone number"
        }, 
        regex : /^[0-9]{10}$/gm, 
        placeholder : "Ex: 0123456789",
        error : "Le numéro de téléphone doit être composé de 10 chiffres"
    }, 
    {
        id : "CONTACTS-TYPE-02",
        name : "mail",
        text : {
            fr : "Mail",
            en : "Email"
        }, 
        placeholder : "Ex: marcdupont@example.fr", 
        regex : /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/gm, 
        error : "Le format de l'adresse mail est invalide"
    }, 
    {
        id : "CONTACTS-TYPE-03",
        name : "other",
        text : {
            fr : "Autre",
            en : "Other"
        }, 
        placeholder : "",
        regex : /^[\s\S]+$/gm,
    }, 

]

export const paymentDeadlines = [
    { id: 1, name: 1, text: '30j' },
    { id: 2, name: 2, text: '30j (fin de mois)' },
    { id: 5, name: 5, text: '45j' },
    { id: 6, name: 6, text: '45j (fin de mois)' },
    { id: 3, name: 3, text: '60j' },
    { id: 4, name: 4, text: '60j (fin de mois)' },
];
  

