import { MerchandiseModuleCategorizer } from "../../merchandises.types";
import { motion } from "framer-motion";
import {ReactComponent as ManufacturedProductSVG} from '../../../../assets/svg/icons/manufactured_products.svg' ; 
import {ReactComponent as FinishedProductSVG} from '../../../../assets/svg/icons/finished_merchandises.svg' ; 
import { useTranslation } from "react-i18next";


export interface NatureMerchProps extends MerchandiseModuleCategorizer {

}

type Mode = {
    id : string, 
    name : "manufactured_product" | "finished_product", 
    title : string, 
    svg : JSX.Element, 
    description : string

}

export default function NatureMerch(props:NatureMerchProps) {
    const {t} = useTranslation()
    const translate_merch = (text:string) => t(`merchandises.${text}`)
    const modes:Mode[] = [
        {
            id : "NATURE-MERCH-01", 
            name : "manufactured_product",
            title : translate_merch("manager.manufactured_product-title"),
            svg : <ManufacturedProductSVG/>, 
            description : translate_merch("manager.manufactured_product-content")
        }, 
        {
            id : "NATURE-MERCH-02", 
            name :  "finished_product",
            title : translate_merch("manager.finished_product-title"),
            svg : <FinishedProductSVG/>, 
            description : translate_merch("manager.finished_product-content")
        }
    ]
    return (
        <motion.div 
            exit={{opacity : 0, transition : {duration : 0.1, delay : 0.2}}}
            initial={{opacity : 0}}
            animate={{opacity : 1, transition : {duration : 0, delay : 0}}}
            className = "nature-merch"
        >
            <div className="nature-merch--title">
                {translate_merch("manager.nature-merch-title")}
            </div>
            <div className="nature-merch--container">
                {
                    modes.map(mode => {
                        return (
                            <motion.div 
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick = {() => props.setState((state => ({...state, nature : mode.name})))}
                                className="nature-merch--container--item" 
                                key = {mode.id}
                            >
                                <div className="nature-merch--container--item--svg">
                                    {mode.svg}
                                </div>
                                <div className="nature-merch--container--item--title">
                                    {mode.title}
                                </div>
                                <div className="nature-merch--container--item--description">
                                    {mode.description}
                                </div>
                            </motion.div>
                        )
                    })
                }

            </div>
        </motion.div>
    )
}