import { MerchandiseModuleCategorizer } from "../../merchandises.types";
import { hostname } from "../../../../config/server.config";



export interface QRCodeMerchProps extends MerchandiseModuleCategorizer {

}

export default function QRCodeMerch(props:QRCodeMerchProps) {
    console.log(props.state.merchandises)
    return (
        <div className="qrcode-merch">
            <div>
            {
                props.state.merchandises.map((el:any, index:number) => (
                    <div
                        key = {`QRCODE-MERCH-${index}`}
                    >
                        <p>{el.code}</p>
                        <img src={`${hostname}/${el.qrcode_path}`} alt={el.code} />

                    </div>
                ))
            }
            </div>
            

        </div>
    )
}
