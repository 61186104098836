import {motion} from 'framer-motion'

export interface BooleanButtonProps {
    state : boolean, 
    setState : React.Dispatch<React.SetStateAction<boolean>>,
}

export default function BooleanButton(props:BooleanButtonProps) {
    return (
        <div 
            className={`boolean-button ${props.state ? "boolean-button__active" : ""}`}
            onClick={() => props.setState(!props.state)}
        >
            <motion.div 
                className="handle" 
                variants = {{
                    on: { x: 22 },
                    off: { x: 2 }
                }}
                animate={props.state ? "on" : "off"}
                layout transition={{type: "spring", stiffness: 700, damping: 30}}
            />
        </div>
    )
}