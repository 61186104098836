/* eslint-disable @typescript-eslint/no-unused-vars */
import { Axios } from "axios";
import { MerchandiseModuleCategorizer } from "../../merchandises.types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StringUtils } from "tc-minibox";


export interface MonetaryMerchProps extends MerchandiseModuleCategorizer {
    server : Axios
}

export default function MonetaryMerch(props:MonetaryMerchProps) {
    // * GENERAL VARIABLES
    const {t} = useTranslation()
    const [cost_price, setCostPrice] = useState<number>(0)

    // * VARIABLES
    const cost_price_value = (unit_rp_amount:number|null, quantity:number|null, exchange_rate:number|null) => {
        const current_unit_rp_amount = unit_rp_amount?? props.state.unit_rp_amount
        const current_quantity = quantity?? props.state.quantity
        const current_exchange_rate = exchange_rate?? props.state.exchange_rate

        const result = ((current_unit_rp_amount * current_quantity) * current_exchange_rate)
        return parseFloat(result.toFixed(2))
    }

    // * FUNCTIONS
    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:string|RegExp = '') => {
        const value = e.target.value.replaceAll(',', ".")

        // Si reg est une expression régulière, on la teste
        if (reg instanceof RegExp && !reg.test(value)) {
            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
            return;
        }

        if(["unit_rp_amount", "exchange_rate"].includes(e.target.name)) {
            return props.setState(state => {

                return {
                    ...state, 
                    [e.target.name] : value.replace(',', '.')
                }
            })
        }


        props.setState(state => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }

    const handleBlur = (name:"exchange_rate"|"quantity"|"unit_rp_amount") => {
        /*if(props.state[name].length === 0) {
            props.setState(state => {
                return {
                    ...state, 
                    [name] : "0"
                }
            })
        }*/
    }

    return (
        <div className = "monetary-merch">
            <div>
                <label htmlFor = "quantity">{t('merchandises.table.quantity')}</label>
                <input 
                    type="text" 
                    name = "quantity"
                    value = {props.state.quantity}
                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeInput(e, /^(?:\s*|\d+)$/)
                        if(e.target.value.length !== 0) {
                            setCostPrice(cost_price_value(null, parseInt(e.target.value), null))
                        }
                    }}
                    onBlur = {() => {
                        handleBlur("quantity")
                    }}
                />
            </div>
            <div>
                <label htmlFor = "unit_rp_amount">{t('merchandises.table.unit_rp_amount')}</label>
                <input 
                    type="text" 
                    name = "unit_rp_amount"
                    value = {props.state.unit_rp_amount}
                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeInput(e, /^\d*\.?\d*$/)
                        if(e.target.value.length !== 0) {
                            setCostPrice(cost_price_value(parseFloat(e.target.value), null, null))
                        }
                    }}
                    onBlur = {() => handleBlur("unit_rp_amount")}
                />
            </div>

            {
                props.state.nature === "finished_product" ? 
                    <React.Fragment>
                        {
                            props.state.id_supplier === null ? 
                                <div className="monetary-merch--status-error">{t('merchandises.status.select-supplier')}</div>
                            : 
                                null
                        }

                        {
                            props.state.id_supplier === null && props.state.id_supplier !== null ? 
                                <div className="monetary-merch--status-error">{t('merchandises.status.select-invoice')}</div>
                            : 
                                null
                        }
                        
                        {
                            (props.state.exchange_rate === 0 && props.state.id_inv !== null) ? 
                                <div className="monetary-merch--status-error">{t('merchandises.status.no-inv-transaction-rate')}</div>
                            : 
                                null
                        }
                    </React.Fragment>
                : 
                    <React.Fragment>
                        {
                            (props.state.exchange_rate === 0) ? 
                                <div className="monetary-merch--status-error">{t('merchandises.status.no-inv-transaction-rate')}</div>
                            : 
                                null
                        }
                    </React.Fragment>
            }


            
            {
                ![props.state.unit_rp_amount, props.state.exchange_rate, props.state.quantity].includes(0) ? 
                    <div className="monetary-merch--summary">
                        <p>
                            <span>{t("Total IDR")}</span>
                            <span>{(props.state.unit_rp_amount * props.state.quantity).toFixed(2)} IDR</span>
                        </p>
                        <p>
                            <span>{t("merchandises.table.exchange_rate")}</span>
                            <span>{(props.state.exchange_rate).toFixed(2)} IDR</span>
                        </p>
                        <p>
                            <span>{t("merchandises.table.cost_price")}</span>
                            <span>{(props.state.quantity * props.state.unit_rp_amount / props.state.exchange_rate).toFixed(2)} €</span>
                        </p>
                    </div>
                : 
                    null
            }

            {
                /*
                            <div>
                <label htmlFor = "exchange_rate">Taux de change</label>
                <input 
                    type="text" 
                    name = "exchange_rate"
                    value = {`${props.state.exchange_rate}`}
                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeInput(e, /^\d*\.?\d*$/)
                        if(e.target.value.length !== 0) {
                            setCostPrice(cost_price_value(null, null, parseFloat(e.target.value)))
                        }
                    }}
                    onBlur = {() => handleBlur("exchange_rate")}
                />
            </div>
                            <div>
                <label htmlFor = "cost_price">{t('merchandises.table.cost_price')}</label>
                <input 
                    type="text" 
                    name = "cost_price"
                    value = {cost_price}
                    onChange={(e) => {
                        const value = `${e.target.value}`.length === 0 ? 0 : parseFloat(e.target.value)
                        
                        const final_cost_price = isNaN((value / (props.state.unit_rp_amount * props.state.quantity))) ? 0 : (value / (props.state.unit_rp_amount * props.state.quantity))

                        setCostPrice(value);
                        props.setState((state:any) => {
                            return {
                                ...state, 
                                exchange_rate : final_cost_price
                            }
                        })
                    }}
                    
                />
        </div>
                */
            }

        </div>
    )
}