/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react' ; 
import { useDispatch, useSelector } from 'react-redux';
import { ServerProps } from '../../config/server.config';
import { setLoaderStatus, addRequestStatus, set_popup_warning } from '../../store/global/actions.global';
import { AnimatePresence } from 'framer-motion';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { icons_config, stepsText_position } from '../../config/context.config';
import { DataType, TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { Icons, Table } from 'tc-minibox';
import { PaymentType, paymentDeadlines, type_payments } from './utils.suppliers';
import SupplierManager from './layouts/Manager';
import { useTranslation } from 'react-i18next';
import StepsText from '../../tools/components/general/StepsText';
import { account_user } from '../../store/account/selector.account';


export type SuppliersManager = {
    add : boolean
    initialize : boolean, 
    mode : "listing" | "detail", 
    selected_data? : number | null,
    update : {
        status : boolean, 
        content : any
    } 
}

const initialize_update = {
    status : false, 
    content : {}
}

export default function Suppliers(props:ServerProps) {
    // * VARIABLES
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const user_account = useSelector(account_user)
    const admin = user_account.infos.permission_type === "admin"


    //TO MANAGE COMPONENT
    const [manager, setManager] = useState<SuppliersManager>({
        add : false, 
        initialize : false,
        mode : "listing",
        selected_data : null, 
        update : initialize_update
    })
    const [suppliers, setSuppliers] = useState<DataType>([])

    //DATA 
    const tbody = suppliers.map(supp => {
        return {
            ...supp, 
            payment_choice : type_payments.find((tp:PaymentType) => tp.name === supp.payment_choice)?.text[i18n.language], 
            payment_deadline : paymentDeadlines.find(pd => pd.name === supp.payment_deadline)?.text
        }
    })


    // * USE EFFECTS

    //INITIALIZE
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des fournisseurs en cours..."}))
            props.server.get('/suppliers/initialize')
            .then((res:any) => {
                const data_formated = res.data.data.map((sup:any )=> Object.fromEntries(Object.entries(sup).map(([key, value]:[string, any]) => {
                    if(["payment_info","address","contact"].includes(key)) {
                        return [key, JSON.parse(value)]
                    }
                    return [key, value]
                })))

                setManager(state => { return {...state, initialize : true}})
                setSuppliers(data_formated)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    // * FUNCTIONS 
    const handleDelete = (e:any) => {

        dispatch(setLoaderStatus({status : true}))
        const selected_data = e.map((sup:any) => sup.id)
        props.server.post('suppliers/delete', selected_data)
        .then(res => {
            setSuppliers(state => state.filter((st:any) => !selected_data.includes(st.id)))
            dispatch(addRequestStatus({
                status : true, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
                dispatch(addRequestStatus({
            status : err.response.data.status, 
            message : t(err.response.data.message)
        }))
            dispatch(setLoaderStatus({status : false}))
        })
        
    }

    const handleDoubleClick = (selected_data:any) => {
        const sd = suppliers.find(supp => supp.id === selected_data.id)

        setManager(state => {
            return{
                ...state, 
                add : true, 
                update : {
                    status : true, 
                    content : {
                        ...sd, 
                    }
                }
        }})
    }

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : t("suppliers.table.code"), 
                    name : "code", 
                }, 
    
                style : {
                    width : "100px",
                }
            },
            {
                info : {
                    text : t("suppliers.table.name"), 
                    name : "name", 
                }, 
    
                style : {
                    width : "calc(100% - 200px - 100px)",
                }
            },
            {
                info : {
                    text : t("suppliers.table.payment_choice"), 
                    name : "payment_choice",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody,
        setData : setSuppliers,
        header : {
            buttons : {
                include : admin ? ["add", "delete"] : ["add"], 
                actions : {
                    add: () => setManager(state => {return {...state, add : true}}), 
                    delete : (e:any) => {
                        if(e.length === 0) {
                            return dispatch(addRequestStatus({
                                status : false, 
                                message : t("suppliers.status.select-supplier")
                            }))
                        }

                        return dispatch(set_popup_warning({
                            status : true, 
                            text : t('suppliers.status.are-you-sure-delete-supplier'), 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 

                }, 
        
            }, 
            custom : (_) => {
                return (
                    <div 
                        onClick = {() => setManager(state => {return{...state, initialize : false}})}
                        className="refresh-button"
                    >
                        <Icons name="arrows-repeat" color="rgb(231, 144, 24)"/>
                    </div>
                )
            }
        }, 
        handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }


    return (
        <React.Fragment>
            <AnimatePresence>
            {

                manager.add ? 
                    <SupplierManager
                        update = {manager.update}
                        data = {suppliers}
                        setData = {setSuppliers}
                        server = {props.server}
                        handleAdd = {(val) => {
                            setSuppliers(state => {return[val, ...state]})
                        }}
                        handleClose = {() => setManager(state => {return{...state, add : false, update : initialize_update}})}
                    />
                : 
                    null
            }
            </AnimatePresence>

            <BaseApp>
                <BaseContainer nameClass = "suppliers">
                    <StepsText 
                        title = {t("suppliers.title")}
                        position = {stepsText_position}
                    />
                    <Table {...table_config}/>
                </BaseContainer>
            </BaseApp>



        </React.Fragment>
    )
}