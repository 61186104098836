
import { Axios } from "axios";
import { MerchandiseModuleCategorizer } from "../../merchandises.types";
import SearchSelect from "../../../../tools/components/general/SearchSelect";
import { useTranslation } from "react-i18next";
import { Icons, StringUtils } from "tc-minibox";
import {ReactComponent as NoDataRafiki } from '../../../../assets/svg/illustrations/no-data-rafiki.svg' ; 
import {ReactComponent as SearchRafiki } from '../../../../assets/svg/illustrations/search-rafiki.svg' ;
import {ReactComponent as DropDownMenuRafiki} from '../../../../assets/svg/illustrations/dropdown-menu-rafiki.svg'
import React, { useEffect, useRef, useState } from "react";
import {AnimatePresence, motion} from 'framer-motion'
import ContainerLoad from "../../../../tools/components/general/ContainerLoad";
import BasePopup from "../../../../tools/components/general/BasePopup";
import MainButton from "../../../../tools/components/general/MainButton";
import { useDispatch } from "react-redux";
import { addRequestStatus } from "../../../../store/global/actions.global";


export interface BillingMerchProps extends MerchandiseModuleCategorizer {
    server : Axios
}

interface Item {
    id: number;
    content: string;
}

interface DraggableItemProps {
    item: Item;
    onDragEnd: (id: number) => void;
}

interface BillingMerchManager {
    search_string : string, 
    load : boolean, 
    dropped_mat : any | null,
}

export default function BillingMerch(props:BillingMerchProps) {
    const {t} = useTranslation()
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const dispatch = useDispatch()
    
    const [manager, setManager] = useState<BillingMerchManager>({
        search_string : "", 
        load : false, 
        dropped_mat : null
    })

    const [rawMat, setRawMat] = useState<any[]>([]);

    const itemsRefs = useRef<{ [key: number]: HTMLElement | null }>({});
    const dropzoneRef = useRef<HTMLDivElement>(null);
    const quantityRef = useRef<HTMLInputElement>(null);

    const handleDragStart = (itemId:number) => {
        // Assurez-vous que l'objet cible est un élément HTMLElement
        const element = itemsRefs.current[itemId];

        if (element) {
            const rect = element.getBoundingClientRect();
            const width = rect.width;
    
            element.style.position = "absolute";
            element.style.width = `${width}px`;
            element.style.zIndex = "1000";
            element.style.border = "1px solid #F3A73B "
            // additional styling and logic...
        }
    };
    

    const handleDragEnd = (item: Item, event: MouseEvent | TouchEvent | PointerEvent) => {
        // Récupération de la référence à la dropzone
        const dropzoneRect = dropzoneRef.current?.getBoundingClientRect();
    
        // Initialisation des coordonnées
        let clientX: number = 0;
        let clientY: number = 0;

        Object.values(itemsRefs.current).forEach(element => {
            // Vérifier que l'élément est bien un HTMLElement avant de modifier son style
            if (element instanceof HTMLElement) {
                element.style.position = 'relative';
                element.style.width = '';
                element.style.top = '';
                element.style.left = '';
                element.style.zIndex = '';
                element.style.border = '';
                // Réinitialiser d'autres styles si nécessaire
            }
        });

        // Vérification du type d'événement et extraction des coordonnées
        if (event instanceof MouseEvent) {
            clientX = event.clientX;
            clientY = event.clientY;
        } else if (event instanceof TouchEvent && event.changedTouches.length > 0) {
            clientX = event.changedTouches[0].clientX;
            clientY = event.changedTouches[0].clientY;
        } else if (window.PointerEvent && event instanceof PointerEvent) {
            clientX = event.clientX;
            clientY = event.clientY;
        }

        // Vérification si l'élément a été lâché dans la dropzone

        if (dropzoneRect && clientX >= dropzoneRect.left && clientX <= dropzoneRect.right && clientY >= dropzoneRect.top && clientY <= dropzoneRect.bottom) {
            // L'élément a été lâché dans la dropzone

            setManager(state => ({...state, dropped_mat : item}))
        }
    };

    const handleSearch = (e: any) => {
        const value = e.target.value;
        setManager(state => ({ ...state, search_string: value }));
    
        // Effacer le timeout précédent pour éviter les déclenchements multiples
        if (timeoutId) clearTimeout(timeoutId);

        if(value.length <= 2) {
            setManager(state => ({ ...state, load: false }));
            return setRawMat([]);
        }
    
        // Utiliser la valeur saisie directement
        if (value.length > 2) {
            setManager(state => ({ ...state, load: true }));
    
            // Enregistrer le nouveau timeout ID
            const newTimeoutId = setTimeout(() => {
                return props.server.post(`/raw_materials/search`, { search_string: value, ids : props.state.raw_materials.map((item:any) => item.id) })
                    .then((res: any) => {
                        setRawMat(res.data.data);
                        setManager(state => ({ ...state, load: false }));
                    })
                    .catch(() => {
                        setRawMat([]);
                        setManager(state => ({ ...state, load: false }));
                    });
            }, 500);
    
            // Mettre à jour l'ID du timeout dans l'état
            setTimeoutId(newTimeoutId);
        }
    };

    const handleDeleteItem = (id:number) => {
        setRawMat(prevItems => [...prevItems, props.state.raw_materials.find(i => i.id === id)]);
        props.setState((state:any) => ({...state, raw_materials : state.raw_materials.filter((i:any) => i.id !== id)}))

        // Calcul de la somme pondérée et de la quantité totale
        const raw_mat = props.state.raw_materials.filter((i:any) => i.id !== id).filter((el) => parseInt(el.exchange_rate) !== 0)
        let weightedSum = 0;
        let totalQuantity = 0;

        if(raw_mat.length > 0) {
            raw_mat.map(el => {return{...el, quantity_choosed : parseInt(el.quantity_choosed)}}).forEach(item => {
                const quantity = parseInt(item.quantity_choosed, 10);
                const exchangeRate = item.exchange_rate;
                weightedSum += quantity * exchangeRate;
                totalQuantity += quantity;
            });
            const exchange_rate = weightedSum / totalQuantity;
            props.setState((state:any) => ({...state, exchange_rate : exchange_rate}))
        } else {
            props.setState((state:any) => ({...state, exchange_rate : 0}))
        }

    }

    const handleSubmitQuantityChoosed = () => {
        var data = props.state.raw_materials
        if(manager.dropped_mat.quantity_choosed === "" || parseInt(manager.dropped_mat.quantity_choosed) === 0) {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("merchandises.manager.quantity-required"),
            }))
        }  
        
        if(props.state.raw_materials.find(i => i.id === manager.dropped_mat.id)) {
            data = data.map((item:any) => {
                if(item.id === manager.dropped_mat.id) {
                    return {...item, quantity_choosed : manager.dropped_mat.quantity_choosed}
                }
                return item
            })
            props.setState((state:any) => ({
                ...state, 
                raw_materials : state.raw_materials.map((item:any) => {
                    if(item.id === manager.dropped_mat.id) {
                        return {...item, quantity_choosed : manager.dropped_mat.quantity_choosed}
                    }
                    return item
                })
            }))
        } else {
            data = [...data, {...manager.dropped_mat, quantity_choosed : manager.dropped_mat.quantity_choosed}]
            props.setState((state:any) => ({
                ...state, 
                raw_materials : [...state.raw_materials, {...manager.dropped_mat, quantity_choosed : manager.dropped_mat.quantity_choosed}]
            }))
            setRawMat(prevItems => prevItems.filter(i => i.id !== manager.dropped_mat.id));

            if(rawMat.filter(i => i.id !== manager.dropped_mat.id).length === 0) {
                setManager(state => ({...state, search_string : ""}))
            }
        }

        // Calcul de la somme pondérée et de la quantité totale
        const raw_mat = data.filter((el) => parseInt(el.exchange_rate) !== 0)
        let weightedSum = 0;
        let totalQuantity = 0;

        if(raw_mat.length > 0) {
            raw_mat.map(el => {return{...el, quantity_choosed : parseInt(el.quantity_choosed)}}).forEach(item => {
                const quantity = parseInt(item.quantity_choosed, 10);
                const exchangeRate = item.exchange_rate;
                weightedSum += quantity * exchangeRate;
                totalQuantity += quantity;
            });
            const exchange_rate = weightedSum / totalQuantity;
            console.log(weightedSum, totalQuantity, exchange_rate)
            props.setState((state:any) => ({...state, exchange_rate : exchange_rate}))
        }

        setManager(state => ({...state, dropped_mat : null}))


        
        
        

    }
    useEffect(() => {
        if(manager.dropped_mat !== null) {
            quantityRef.current?.focus()
        }
    }, [manager.dropped_mat])

    return (
        <div className="billing-merch">
            {
                props.state.nature === "finished_product" ? 
                    <React.Fragment>
                    <div id = "id_supplier" style = {{zIndex : 1001, position : "relative"}}>
                        <label htmlFor = 'id_supplier'>{t('merchandises.table.supplier')}</label>
                        <SearchSelect
                            {...{
                                adding : false, 
                                handleAdd : (e) => console.log(e), 
                                handleClick : (data) => props.setState((state:any) => {return {...state, id_supplier : data.id}}),
                                handleDelete : () => props.setState((state:any) => {return {...state, id_supplier : null}}),
                                no_data_text : "Aucun fournisseur trouvé", 
                                server : {
                                    axios : props.server,
                                    route : "/suppliers/search",
                                },
                                value : props.state.id_supplier
                            }}
                        />
                    </div>
                    {
                        props.state.id_supplier !== null ?
                            <div id = "id_inv">
                                <label htmlFor = "id_inv">{t('merchandises.manager.invoice')}</label>
                                <SearchSelect
                                    {...{
                                        adding : false, 
                                        handleAdd : (e) => console.log(e), 
                                        handleClick : (data) => props.setState((state:any) => {
                                            console.log(data)
                                            const rate = StringUtils.isUndefinedValue(data.average_rate) ? 0 : data.average_rate
                                            return {
                                                ...state, 
                                                id_inv : data.id, 
                                                exchange_rate : rate, 
                                                cost_price : rate * state.unit_rp_amount * state.quantity
                                        }}),
                                        handleDelete : () => props.setState((state:any) => {return {...state, id_inv : null, exchange_rate : 0}}),
                                        no_data_text : "Aucune facture trouvée", 
                                        server : {
                                            axios : props.server,
                                            route : "/invoices/search",
                                            body : {id_supplier : props.state.id_supplier, type : "finished_products"}
                                        },
                                        value : props.state.id_inv
                                    }}
                                />
                            </div>
                            :
                                null
                    }
                    </React.Fragment>
                : 
                    <div className="raw-materials">
                        <div className="draggable-items">
                            <div className="draggable-items--search">
                                <input 
                                    type="text" 
                                    placeholder="Search" 
                                    value = {manager.search_string}
                                    onChange = {(e) => handleSearch(e)}
                                />
                                <Icons name = "magnifying-glass" color = "#c6c7cc"/>
                            </div>
                            <div className="draggable-items--list">
                                <ContainerLoad load = {manager.load}>

                                        {
                                            rawMat.length === 0 ?
                                                manager.search_string.length <= 2 ? 
                                                    <div className="draggable-items__status draggable-items__status--search">
                                                        <SearchRafiki/>
                                                        <p>{t("merchandises.manager.tap-to-search")}</p>
                                                    </div>
                                                : 
                                                    <div className="draggable-items__status draggable-items__status--no-data">
                                                        <NoDataRafiki/>
                                                        <p>{t("merchandises.manager.no-rawmat-found")}</p>
                                                    </div>
                                            : 
                                                <div>
                                                <AnimatePresence>
                                                {
                                                    rawMat.map((item:any, index) => (
                                                    <motion.div
                                                        ref={(el) => (itemsRefs.current[item.id] = el)}
                                                        key = {`${JSON.stringify(item)}`}
                                                        drag
                                                        dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }}
                                                        dragElastic={1}
                                                        onDragStart={() => handleDragStart(item.id)}
                                                        onDragEnd={(event) => handleDragEnd(item, event)}
                                                        whileDrag={{ scale: 1.05 }}
                                                        exit={{ opacity: 0, scale: 0 }}
                                                        initial={{ opacity: 0, scale: 0 }}
                                                        animate={{ opacity: 1, scale: 1, transition: { duration: 0.1 + (index * 0.2) } }}
                                                    >
                                                        {item.content}
                                                        <div className="item--identification">
                                                            <p>{item.name}</p>
                                                            <p>{item.code}</p>
                                                        </div>
                                                        <div className="item--monetary">
                                                            <p>{`${item.unit_rp_amount} IDR`}</p>
                                                            <p>{item.exchange_rate === 0 ? "N/A" : ` ${item.exchange_rate} IDR / 1 EUR`}</p>
                                                        </div>
                                                        <div className="item__buttons">
                                                            <motion.button
                                                                className="item__buttons--quantity"
                                                            >
                                                                <span>x</span>
                                                                <span>{item.quantity}</span>
                                                            </motion.button>
                                                        </div>
                                                    </motion.div>
                                                    ))
                                                }
                                                </AnimatePresence>
                                                </div>
                                        }

                                </ContainerLoad>
                            </div>
                        </div>
                        <div className="dropzone" ref = {dropzoneRef}>
                            {
                                props.state.raw_materials.length === 0 ? 
                                    <div className="dropzone__status">
                                        <DropDownMenuRafiki/>
                                        <p>{t("merchandises.manager.dragndrop-rawmat")}</p>
                                    </div>
                                : 

                                <div>
                                    <AnimatePresence>
                                    {
                                        props.state.raw_materials.map((item:any, index) => (
                                            <motion.div
                                                ref={(el) => (itemsRefs.current[item.id] = el)}
                                                key = {`${JSON.stringify(item)}`}
                                                exit={{ opacity: 0, scale: 0 }}
                                                initial={{ opacity: 0, scale: 0 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                            >
                                                <div className="item--identification">
                                                    <p>{item.name}</p>
                                                    <p>{item.code}</p>
                                                </div>
                                                <div className="item--monetary">
                                                    <p>{`${item.unit_rp_amount} IDR`}</p>
                                                    <p>{item.exchange_rate === 0 ? "N/A" : ` ${item.exchange_rate} IDR / 1 EUR`}</p>
                                                </div>
                                                <div className="item__buttons">
                                                    <motion.button
                                                        whileTap={{ scale: 0.90 }}
                                                        whileHover={{ scale: 1.1 }} 
                                                        onClick = {() => setManager(state => ({...state, dropped_mat : item}))}
                                                        className="item__buttons--quantity"
                                                    >
                                                        <span>x</span>
                                                        <span>{item.quantity_choosed}</span>
                                                    </motion.button>
                                                    <motion.button
                                                        whileTap={{ scale: 0.90 }}
                                                        whileHover={{ scale: 1.1 }} 
                                                        className="item__buttons--delete"
                                                        onClick = {() => handleDeleteItem(item.id)}
                                                    >
                                                        <Icons name = "xmark" color = "white" />
                                                    </motion.button>
                                                </div>
                                            </motion.div>
                                        ))
                                    }
                                    </AnimatePresence>
                                </div>
                            }
                        </div>
                        <AnimatePresence>
                        {
                            manager.dropped_mat !== null ? 
                                <BasePopup 
                                    nameClass="quantity-popup" 
                                    handleClose={() => {
                                        setManager(state => ({...state, dropped_mat : null}))
                                    }}
                                >
                                    <div className="quantity-popup--container">
                                        <label htmlFor="quantity">{t("merchandises.manager.quantity")}</label>
                                        <p>{`${t("merchandises.manager.available")}: ${manager.dropped_mat.quantity}`}</p>
                                        <input 
                                            ref = {quantityRef}
                                            type="text" 
                                            name="quantity" 
                                            id="quantity"
                                            value = {manager.dropped_mat.quantity_choosed}
                                            onChange = {(e) => {
                                                const value = e.target.value.replaceAll(',', ".")
        
                                                if (!/^[0-9]*$/.test(value)) {
                                                    // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
                                                    // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
                                                    return;
                                                }

                                                if (parseInt(value) > manager.dropped_mat.quantity){ 
                                                    return dispatch(addRequestStatus({
                                                        status : false, 
                                                        message : t("merchandises.manager.quantity-exceed")
                                                    }))
                                                }                                                
                                                setManager(state => ({...state, dropped_mat : {...state.dropped_mat, quantity_choosed : e.target.value}}))
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleSubmitQuantityChoosed()
                                                }
                                            }}
                                        />
                                        <MainButton
                                            handleClick={() => handleSubmitQuantityChoosed()}
                                        >
                                            {t("commons.validate")}
                                        </MainButton>
                                    </div>
                                </BasePopup>
                            : 
                                null
                        }
                        </AnimatePresence>

                    </div>
            }


            
        </div>
    )
}



