import { Axios } from "axios";
import { MerchandiseModuleCategorizer } from "../../../merchandises/merchandises.types";
import SearchSelect from "../../../../tools/components/general/SearchSelect";
import { useTranslation } from "react-i18next";
import { StringUtils } from "tc-minibox";
import { RawMatModuleCategorizer } from "../../raw_materials.types";


export interface BillingMerchProps extends RawMatModuleCategorizer {
    server : Axios
}

export default function BillingRawMat(props:BillingMerchProps) {
    const {t} = useTranslation()

    return (
        <div className="billing-merch">
            <div id = "id_supplier">
                <label htmlFor = 'id_supplier'>{t('merchandises.table.supplier')}</label>
                <SearchSelect
                    {...{
                        adding : false, 
                        handleAdd : (e) => console.log(e), 
                        handleClick : (data) => props.setState((state:any) => {return {...state, id_supplier : data.id}}),
                        handleDelete : () => props.setState((state:any) => {return {...state, id_supplier : null}}),
                        no_data_text : "Aucun fournisseur trouvé", 
                        server : {
                            axios : props.server,
                            route : "/suppliers/search",
                        },
                        value : props.state.id_supplier
                    }}
                />
            </div>
            {
                props.state.id_supplier !== null ?
                <div id = "id_inv">
                    <label htmlFor = "id_inv">{t('merchandises.manager.invoice')}</label>
                    <SearchSelect
                        {...{
                            adding : false, 
                            handleAdd : (e) => console.log(e), 
                            handleClick : (data) => props.setState((state:any) => {
                                console.log(data)
                                const rate = StringUtils.isUndefinedValue(data.average_rate) ? 0 : data.average_rate
                                return {
                                    ...state, 
                                    id_inv : data.id, 
                                    exchange_rate : rate, 
                                    cost_price : rate * state.unit_rp_amount * state.quantity
                            }}),
                            handleDelete : () => props.setState((state:any) => {return {...state, id_inv : null, exchange_rate : 0}}),
                            no_data_text : "Aucune facture trouvée", 
                            server : {
                                axios : props.server,
                                route : "/invoices/search",
                                body : {id_supplier : props.state.id_supplier, type : "raw_materials"}
                            },
                            value : props.state.id_inv
                        }}
                    />
                </div>
                :
                    null
            }

            
        </div>
    )
}