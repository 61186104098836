/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import BasePopup from "../../../tools/components/general/BasePopup";
import SearchSelect from "../../../tools/components/general/SearchSelect";
import { InvoiceModel } from "../invoices.types";
import { ServerProps } from "../../../config/server.config";
import { DatePicker } from "tc-minibox";
import {v4} from 'uuid'
import ImportModule, { ImportProps } from "../../../tools/components/general/ImportModule";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import MainButton from "../../../tools/components/general/MainButton";
import { useTranslation } from "react-i18next";
import { inv_types } from "../invoices.utils";
import DropDown from "../../../tools/components/general/DropDown";

export interface InvoicesManProps extends ServerProps {
    handleClose : () => void
    handleRerender : () => void
}

export interface EventsProps {
    hover : string | null
}

export default function InvoicesMan(props:InvoicesManProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const {t} = useTranslation()

    //* STATES
    const [form, setForm] = useState<InvoiceModel>({
        id_supplier : null, 
        token : v4(),
        type : inv_types[0].name,
        date: new Date(),
        inv_number : "", 
        file : {}, 
        rp_amount : "", 
    })
    const [previewFile, setPreviewFile] = useState<string>('')
    const [events, setEvents] = useState<EventsProps>({
        hover : null
    })

    // * FUNCTIONS 
    const handleReimport = () => {
        setForm(state => {return{...state, file : {}}});
        setPreviewFile('')
    }

    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:string|RegExp = '') => {
        const value = e.target.value

        // Si reg est une expression régulière, on la teste
        if (reg instanceof RegExp && !reg.test(value)) {
            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
            return;
        }

        if(["rp_amount", "exchange_rate"].includes(e.target.name)) {
            return setForm(state => {
                return {
                    ...state, 
                    [e.target.name] : value.replace(',', '.')
                }
            })
        }


        setForm(state => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }
    
    const handleSubmit = () => {

        // ! FAIRE LES VERIFS INPUTS 
        if(form.id_supplier === null) {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.statusRequest.error-supplier")
            }))
        }
        if(form.inv_number === "") {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.statusRequest.error-inv-number")
            }))
        }
        if(form.rp_amount === "") {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.statusRequest.error-rp-amount")
            }))
        }
        if(form.file instanceof File === false) {
            return dispatch(addRequestStatus({
                status : false, 
                message : t("invoices.statusRequest.error-file")
            }))
        }

        const container = new FormData()
        container.append("token", form.token)
        container.append("type", form.type)
        container.append("id_supplier", (form.id_supplier || '').toString());
        container.append("date", form.date.toString())
        container.append("inv_number", form.inv_number)
        container.append("rp_amount", form.rp_amount)
        container.append("file", form.file as File)

        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('/invoices/create', container, { headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            dispatch(addRequestStatus({
                status : true, 
                message : t(res.data.message)
            }))
            props.handleRerender()
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
              dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }
    // * CONFIG COMPONENTS
    const import_config:ImportProps = {
        subtitle : t("invoices.manager.text-import"),
        nameClass : 'new-affair--import', 
        handleSubmit : (file:File) => {
            const mimetypes_accepted = [
                "image/jpeg",
                "image/png",
                "application/pdf",
            ];
            
            
            if(!mimetypes_accepted.includes(file.type)) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : t("statusRequest.error-file-format")
                }))
            }
            const fileURL = URL.createObjectURL(file);
            setForm(state => {return{...state, file : file}});
            return setPreviewFile(fileURL);

            //dispatch(setLoaderStatus({status : true, message : "Traitement du fichier en cours..."}))



        }
    }

    

    return(
        <BasePopup nameClass="invoices-man" handleClose = {props.handleClose}>

            <div className="invoices-man--leftside">
                <label htmlFor = "date">{t("invoices.table.type")}</label>
                <div className="type">
                    <DropDown
                        reference={"TYPE_OF_CONTACT_HOVER"}
                        hover={events.hover}
                        setHover={(e) => setEvents((state) => { return { ...state, hover: e } })}
                        value={form.type}
                        setValue={(e) => setForm((state) => { return { ...state, type: e.name } })}
                        list={inv_types.map((type) => { 
                            return { 
                                ...type, 
                                id: type.name, 
                                name: type.name, 
                                text: t(type.label),
                            } 
                        })}
                    />
                </div>
                <div className="id_supplier">
                    <label htmlFor = "supplier">{t("invoices.table.supplier")}</label>
                    <SearchSelect
                        {...{
                            text_no_data : "Aucune affaire trouvée", 
                            handleClick : (data) => setForm((state:any) => {return {...state, id_supplier : data.id}}),
                            handleDelete : () => setForm((state:any) => {return {...state, id_supplier : null}}),
                            no_data_text : t("invoices.status.no-supplier-found"), 
                            server : {
                                axios : props.server,
                                route : "/suppliers/search",
                            },
                            value : form.id_supplier
                        }}
                    />

                </div>
                <div className="date">
                    <label htmlFor = "date">{t("invoices.table.date")}</label>
                    <DatePicker
                        selected={new Date(form.date)} 
                        dateFormat="dd/MM/yyyy"
                        onChange = {(e:any) => setForm(state => {return{...state, date : e}})}
                        locale="fr"       
                    />
                </div>
                <div className="inv-number">
                    <label htmlFor = "inv-number">{t("invoices.table.inv-number")}</label>
                    <input 
                        type = "text"
                        name = "inv_number"
                        value = {form.inv_number}
                        onChange = {(e:any) => handleChangeInput(e, /^[^\s]+$/g)}
                    />
                </div>
                <div className = "rp_amount">
                    <label htmlFor = "rp_amount">{t("invoices.table.rp_amount")}</label>
                    <input 
                        type = "text"
                        name = "rp_amount"
                        value = {form.rp_amount}
                        onChange = {(e:any) => handleChangeInput(e, /^(?:\d+(?:[\.,]\d*)?|[\.,]\d+|)$/g)}   
                    />
                </div>

                <MainButton handleClick={() => handleSubmit()}>
                    {t("commons.save")}
                </MainButton>
            </div>

            <div className="invoices-man--rightside">
                {
                    form.file instanceof File ? 
                        <div className = "preview">
                            <div className="preview--header">
                                <button onClick = {handleReimport}>
                                    {t("invoices.manager.reimport")}
                                </button>

                            </div>
                            <div className="preview--body">
                            {
                                form.file.type.startsWith("image/") ? 
                                    <img src = {previewFile} alt = "preview" />
                                :
                                    <iframe src = {previewFile} title = "preview" />
                            }
                            </div>

                        </div>
                    : 
                        <React.Fragment>
                            <label htmlFor="file">{t("invoices.manager.myinvoice")}</label>
                            <ImportModule {...import_config} />
                        </React.Fragment>

                }

            </div>
        </BasePopup>
    )
}