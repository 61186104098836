

type InvType = {
    name: 'raw_materials' | 'finished_products';
    label: string;
};

export const inv_types:InvType[] =  [
    {name: 'raw_materials', label: 'invoices.table.raw_materials'},
    {name: 'finished_products', label: 'invoices.table.finished_products'},
]
