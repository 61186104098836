import {useState} from 'react' ; 
import {AnimationProps, motion} from 'framer-motion'
import { Icons } from 'tc-minibox';
import { icons_config } from '../../../config/context.config';

export interface CategorizerConfig {
    id: string;
    name: string;
    svg: string;
    component: JSX.Element;
  };

export type CategorizerManagerProps = {
    resize? : {
        status : boolean,
        key : string | number | null, 
        value : string | number | null, 
        setValue : (value:string | number | null) => void
    }
    config: CategorizerConfig[];
    rerender? : boolean,
    animation? : {
        header? : AnimationProps,  
        container? : AnimationProps
    }
};

  
  export default function CategorizerManager(props: CategorizerManagerProps) {
    const rerender = props.rerender?? true ; 
    const [manager, setManager] = useState({
      selected_category: props.config[0]?.id,
    });
  
    const handleChangeCategorizer = (id: string) =>
      setManager((state) => {
        return { ...state, selected_category: id };
    });

    const selectedConfig = props.config.find(cf => cf.id === manager.selected_category);
    
    return (
        <div 
            className='categorizer-manager'
        >
            {
                props.resize ? 
                    <motion.button 
                        onClick = {() => props.resize?.setValue(props.resize?.value !== props.resize?.key ? props.resize?.key : null)}
                        className={`resize resize--${props.resize?.key === props.resize?.value ? "active" : ""}`}
                    >
                        <Icons name = {props.resize?.key === props.resize?.value ? "up-right-and-down-left-from-center" : "down-left-and-up-right-to-center"} mode = "duotone" color = "white"/>
                    </motion.button>
                :  
                    null
            }
            {
                props.resize?.key === props.resize?.value ? 
                    <motion.div 
                        exit = {{opacity : 0}}
                        animate = {{opacity : 1, transition : {delay : 0.5, duration : 0.5}}}
                        initial = {{opacity : 0}}
                        className='categorizer-manager--background'
                        onClick = {() => props.resize?.setValue(props.resize?.value !== props.resize?.key ? props.resize?.key : null)}
                    >

                    </motion.div> 
            : null}
            <div className='categorizer-manager__header'>
                {
                    props.config.map((cf, cf_index) => (
                        <motion.div 
                            exit = {{x : 150}}
                            initial = {{x : 150}}
                            animate = {{x : 0}}
                            transition = {{
                                type : "linear", 
                                delay : 0.1 + (cf_index * 0.1)
                            }}
                            onClick = {() => handleChangeCategorizer(cf.id)}
                            key ={cf.id} 
                            className={manager.selected_category === cf.id ? "active" : ""}
                        >
                            <Icons name = {cf.svg} {...icons_config} />
                            <p>{cf.name}</p>
                        </motion.div>
                    ))
                }
            </div>

            <motion.div 
                {...rerender ? {key : manager.selected_category} : {}}
                exit = {props.animation?.container?.exit ?? {opacity : 0, scale : 0}}
                initial = {props.animation?.container?.initial ?? {opacity : 1, scale : 0,}}
                animate = {props.animation?.container?.animate ?? {opacity : 1, scale : 1,  transition : {duration : 0.2}}}
                className={`categorizer-manager__container categorizer-manager__container--${props.resize?.key === props.resize?.value ? "fullscreen" : ""}`}
            >
                {selectedConfig?.component}
            </motion.div>
        </div>
    )
}